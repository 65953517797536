import { FirstEventTime } from "old/actions/webBookingViewActions";
import { BookingEvent, BookingEventType } from "old/models/bookingEvent";
import WebBookingStepsEnum from "old/models/webBookingStepsEnum";
import * as ReactGA from "react-ga";

const target: string = "*";

const sendGAEvent = (event: string, payload: unknown) => {
    const ga = ReactGA.ga;
    ga("send", "event", "CaspecoBooking", event, payload);
    ga("customerTracking.send", "event", "CaspecoBooking", event, payload);
};

const sendMessage = (event: string, payload: unknown) => {
    if ("parentIFrame" in window) {
        window.parentIFrame.sendMessage(
            {
                eventType: "bookingEvent",
                event,
                payload,
            },
            target
        );
    }
};

let isFirstTime = true;
export const handleEvent = (event: BookingEvent) => {
    switch (event.eventType) {
        case BookingEventType.ChangeDate:
            sendMessage(BookingEventType[event.eventType], {
                ...event,
                date: event.date.format("YYYY-MM-DD"),
            });
            break;
        case BookingEventType.SelectTime:
            sendMessage(BookingEventType[event.eventType], {
                ...event,
                time: event.time.format("YYYY-MM-DDTHH:mm"),
            });
            break;
        case BookingEventType.ChangeStep:
            sendGAEvent(BookingEventType[event.eventType], WebBookingStepsEnum[event.step]);
            sendMessage(BookingEventType[event.eventType], {
                ...event,
                stepName: WebBookingStepsEnum[event.step],
            });
            break;
        case BookingEventType.FinalizeWebBooking:
            sendGAEvent(
                BookingEventType[event.eventType],
                event.bookingDate.format("YYYY-MM-DDTHH:mm")
            );
            sendMessage(BookingEventType[event.eventType], {
                ...event,
                bookingDate: event.bookingDate.format("YYYY-MM-DDTHH:mm"),
            });
            break;
        default:
            console.warn("Event: ", event);
    }

    if (!isFirstTime) {
        new FirstEventTime();
        isFirstTime = false;
    }
};
