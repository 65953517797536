import { List } from "immutable";
import { WebBookingTimeRuleUnit } from "old/models/webBookingTimeRuleUnit";
import { useEffect, useState } from "react";
import { useMobileView } from "utils";

interface TimeTexts {
    iconText: string;
    timeText: string;
}

export const useTimeDisplay = (time: string, selectedTimes: List<WebBookingTimeRuleUnit>): TimeTexts => {
    const isMobileView = useMobileView();
    const [timeTexts, setTimeTexts] = useState<TimeTexts>({ iconText: "", timeText: "" });

    useEffect(() => {
        let formattedTime: string;

        if (selectedTimes?.size > 1 && selectedTimes.get(0)?.isWaitList) {
            const sortedArray = selectedTimes.sort((a, b) => a.start.diff(b.start));
            setTimeTexts({
                timeText: `${sortedArray.first()?.start.format("HH:mm")} +${selectedTimes?.count() - 1}`,
                iconText: "",
            });
            return;
        }

        if (!time) {
            // Check if time is null or undefined
            setTimeTexts({ iconText: "", timeText: "" });
            return;
        }

        // eslint-disable-next-line prefer-const
        formattedTime = time.replace(/\s+/g, ""); // Format time to remove any whitespace
        setTimeTexts({ iconText: "", timeText: formattedTime });
    }, [time, isMobileView, selectedTimes]);

    return timeTexts;
};

export default useTimeDisplay;
