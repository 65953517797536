import { useExternalBookingSettingsQuery } from "api/apiHooks";
import { ThemeProvider } from "caspeco-casper-ui";
import Spinner from "components/spinner/spinner";
import { fallbackTheme } from "fallback-theme";
import Layout from "layout/layout";
import { ErrorProvider } from "context/error-context";
import { useEffect } from "react";
import { ga, initialize } from "react-ga";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import routes from "routes";
import "./variables.less";

const App = () => {
    const { data: settings } = useExternalBookingSettingsQuery();

    useEffect(() => {
        if (!settings?.gaTrackingId) return;
        initialize(settings?.gaTrackingId);
        ga("create", settings?.gaTrackingId, "auto", {
            name: "customerTracking",
        });
        ga("customerTracking.send", "pageview", {
            page: window.location.pathname + window.location.search,
            anonymizeIp: true,
        });
    }, [settings?.gaTrackingId]);

    const themeColors = settings?.themeSettings?.colors || fallbackTheme;
    return (
        <ErrorProvider>
            <ThemeProvider colors={themeColors}>
                <Layout>
                    <RouterProvider
                        router={createBrowserRouter(routes)}
                        fallbackElement={<Spinner />}
                    />
                </Layout>
            </ThemeProvider>
        </ErrorProvider>
    );
};

export default App;
