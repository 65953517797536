import { Button, ThemeSpaceVariable } from "caspeco-casper-ui";
import { useWebBooking } from "hooks";

import { useTranslation } from "react-i18next";

export interface CancelButtonProps {
    onClick: () => void;
    isLoading: boolean;
}

export default function CancelButton({ onClick, isLoading }: CancelButtonProps) {
    const { t } = useTranslation();
    const { data: webBooking } = useWebBooking();

    return (
        <Button
            onClick={onClick}
            variant="tertiary"
            mt={ThemeSpaceVariable.Large}
            isLoading={isLoading}
            alignSelf="flex-end"
        >
            {webBooking.isWaitList()
                ? t("waitList.waitListBookingCancel")
                : t("cancelBookingButton")}
        </Button>
    );
}
