import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from "@chakra-ui/react";
import {
    Flex,
    Heading,
    Icon,
    Icons,
    ThemeColorVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { useBookingViewStore } from "hooks";
import { TimeClicked } from "old/actions/webBookingViewActions";
import { SelectTimeEvent } from "old/models/bookingEvent";
import { WebBookingSection as WebBookingSectionModel } from "old/models/webBookingSection";
import { WebBookingTimeRule } from "old/models/webBookingTimeRule";
import { WebBookingTimeRuleUnit } from "old/models/webBookingTimeRuleUnit";
import { useState } from "react";
import { handleEvent, getUrlParams } from "utils";
import TimeSet from "./time-set";
import { mergeTimeSetsByTitle } from "utils/time-rule-utils";

interface ISectionOfAvailableTimesProps {
    expanded?: boolean;
    webBookingSection: WebBookingSectionModel;
    defaultExpanded: boolean;
}

export default function SectionOfAvailableTimes(props: ISectionOfAvailableTimesProps) {
    const { selectedTimes } = useBookingViewStore();
    const { sectionIds, debug, hostURL, onlineorder } = getUrlParams();
    const { webBookingSection } = props;
    const selectedTimesCount = selectedTimes
        .filter((t) => t.sectionId === props.webBookingSection.id)
        .count();
    const [expanded, setExpanded] = useState(selectedTimesCount > 0 || props.defaultExpanded);

    const handleChange = (indices: number[]) => {
        setExpanded(indices.includes(0));
    };

    const onTimeClick = (time: WebBookingTimeRuleUnit, timeSet: WebBookingTimeRule) => {
        handleEvent(new SelectTimeEvent(time.start, webBookingSection.name, timeSet.title));
        new TimeClicked(time, sectionIds, debug, hostURL);
    };

    const shouldNotRenderSection = !props.webBookingSection.timeSets.find(
        (x) => x.times.count() > 0 || x.isTextRow
    );
    if (shouldNotRenderSection) return null;

    const listOfRules = mergeTimeSetsByTitle(props.webBookingSection.timeSets);

    if (props.defaultExpanded) {
        return (
            <Flex direction="column">
                <Heading as="h5" fontWeight="500" mb={ThemeSpaceVariable.Medium}>
                    {props.webBookingSection.name}
                </Heading>
                {listOfRules.map((timeSet) => (
                    <TimeSet
                        key={`timeSet${timeSet.id}`}
                        timeSet={timeSet}
                        onTimeClick={onTimeClick}
                    />
                ))}
            </Flex>
        );
    }

    return (
        <Accordion
            allowMultiple
            index={expanded ? [0] : []}
            onChange={(indices: number[]) => handleChange(indices)}
            borderColor={ThemeColorVariable.Background}
        >
            {!onlineorder && (
                <AccordionItem>
                    <AccordionButton>
                        <Heading as="h5" fontWeight="500" flex="1" textAlign="left">
                            {props.webBookingSection.name}
                        </Heading>
                        <Icon icon={expanded ? Icons.Closed : Icons.Open} />
                    </AccordionButton>

                    <AccordionPanel>
                        {listOfRules.map((timeSet) => (
                            <TimeSet
                                key={`timeSet${timeSet.id}`}
                                timeSet={timeSet}
                                onTimeClick={onTimeClick}
                            />
                        ))}
                    </AccordionPanel>
                </AccordionItem>
            )}
        </Accordion>
    );
}
