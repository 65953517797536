import App from "app";
import { trackPageView } from "logging/insights";
import { RAYGUN_API_KEY } from "logging/raygunConfig";
import { logError } from "logging/raygunLogger";
import { ErrorProvider } from "context/error-context";
import queryClient from "query-client";
import rg4js from "raygun4js";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "translations/config/i18n";
import { getUrlParams } from "utils";
import "utils/load-script";

const { system } = getUrlParams();

rg4js("apiKey", RAYGUN_API_KEY);
rg4js("enableCrashReporting", true);

rg4js("withTags", [`system:${system}`]);
rg4js("options", {
    captureUnhandledRejections: true,
    allowInsecureSubmissions: true,
    excludedHostnames: ["localhost"],
});

window.addEventListener("error", (event) => {
    logError(event.error);
});

trackPageView("Booking", window.location.pathname);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <ErrorProvider>
            <QueryClientProvider client={queryClient}>
                <App />
                <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            </QueryClientProvider>
        </ErrorProvider>
    </React.StrictMode>
);
