import "./change-url";
import { asBool, asInt, asIntArray, asString, asDate, isLandingPage } from "./parse";
export { setSearch } from "./parse";

export type UrlParams = {
    date: moment.Moment;
    guestsChildren: number;
    inIFrame: boolean;
    debug: boolean;
    advanced: boolean;
    isLandingPage: boolean;
    maitres: boolean;
    paymentLink: boolean;
    customerFriendlyId: string;
    onlineorder: boolean;
    system: string;
    initialStep: string;
    sectionIds: number[];
    chargeId: string;
    webBookingId: string;
    guests: number;
    unitId: number;
    hostURL: string;
    time: string;
    ruleId: number;
    autoScroll: boolean;
    height: boolean;
};

let lastUrl = "";
let cache: UrlParams;
// eslint-disable-next-line prefer-const
export let getUrlParams = () => {
    if (window.location.href !== lastUrl) {
        lastUrl = window.location.href;
        cache = {
            system: asString("system", null),
            unitId: asInt("unitId"),
            // Query Strings
            webBookingId: asString("webBookingId", null),
            initialStep: asString("initialStep", null),
            chargeId: asString("ChargeId", null),
            hostURL: asString("hostURL"),
            time: asString("time"),
            // Date moment
            customerFriendlyId: asString("cfid"),
            date: asDate("date"),
            // Query Number
            ruleId: asInt("r"),
            guests: asInt("guests"),
            guestsChildren: asInt("children"),
            // Query Number[]
            sectionIds: asIntArray("sectionIds"),
            // Query Booleans
            paymentLink: asBool("paymentLink"),
            inIFrame: asBool("inIFrame"),
            autoScroll: asBool("autoScroll"),
            height: asBool("height"),
            advanced: asBool("advanced"),
            onlineorder: asBool("onlineorder"),
            debug: asBool("debug"),
            maitres: asBool("maitres"),
            isLandingPage,
        };
    }
    return cache;
};
