import { Text } from "@chakra-ui/react";
import {
    Box,
    Flex,
    Heading,
    ThemeFontSizeVariable,
    ThemeFontVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { useBookingViewStore } from "hooks";
import { SafeHTML } from "components/safe-HTML";
import BookingStatusEnum from "old/models/bookingStatusEnum";
import ChargeTypeEnum from "old/models/chargeTypeEnum";
import { useTranslation } from "react-i18next";

export default function BookingConditions() {
    const { t } = useTranslation();
    const { webBookingConditions, bookingReservation } = useBookingViewStore();

    const conditionsToRender: JSX.Element[] = [];
    let textLength = 0;

    webBookingConditions?.forEach((condition: string, i: number) => {
        const formattedCondition = condition.replace(/\n/g, "<br>");

        conditionsToRender.push(
            <Box
                key={i}
                mb={ThemeSpaceVariable.Medium}
                fontSize={ThemeFontSizeVariable.Medium}
                mt={ThemeSpaceVariable.Small}
            >
                <SafeHTML
                    key={`condition_${i}`}
                    className="textFromSettings"
                    element="div"
                    options={{
                        allowedTags: ["a", "br"],
                        allowedSchemes: ["tel", "mailto", "https"],
                    }}
                    html={formattedCondition}
                />
            </Box>
        );
        textLength += condition.length;
    });

    if (
        bookingReservation &&
        bookingReservation.chargeType === ChargeTypeEnum.NoShow &&
        bookingReservation.status === BookingStatusEnum.BookingInProgress
    ) {
        conditionsToRender.push(
            <div style={{ marginTop: 10 }}>
                {t("noShowCondition", {
                    value: bookingReservation.chargeShouldPayAmount,
                    amount: "symbol",
                })}
            </div>
        );
    }

    if (conditionsToRender.length < 1) return null;

    return (
        <Flex direction="column" gap={ThemeSpaceVariable.Small} mb={ThemeSpaceVariable.Medium}>
            <Text
                size={ThemeFontSizeVariable.Large}
                fontStyle={ThemeFontVariable.SubHeader}
                fontSize={ThemeFontSizeVariable.Large}
                fontWeight={"medium"}
            >
                {t("informationAndConditions")}
            </Text>
            {textLength > 500 && (
                <Heading as="h6" mt="5">
                    {t("readConditionText")}
                </Heading>
            )}
            {conditionsToRender}
        </Flex>
    );
}
