import {
    Box,
    Button,
    Flex,
    Heading,
    Icon,
    Icons,
    Text,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeFontVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";

type ServerNotReponseProps = {
    lastCall?: { fn: Function; args: unknown[] };
    setLastCall?: Dispatch<SetStateAction<{ fn: Function; args: unknown[] } | undefined>>;
};

export default function ServerDidNotRespond({ lastCall, setLastCall }: ServerNotReponseProps) {
    const { t } = useTranslation();

    const handleRetry = useCallback(() => {
        console.log("handleRetry invoked");
        console.log("Handling retry", lastCall);
        if (lastCall) {
            lastCall.fn(...lastCall.args);
        }
        // Optionally reset the lastCall state after retrying
        if (setLastCall) {
            setLastCall(undefined);
        }
    }, [lastCall, setLastCall]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={ThemeSpaceVariable.Medium}
        >
            <Icon icon={Icons.Sweat} color="#242236" fontSize="60px" />
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={ThemeSpaceVariable.Small}
            >
                <Heading
                    fontWeight="medium"
                    fontFamily={ThemeFontVariable.SubHeader}
                    fontSize={ThemeFontSizeVariable.XLarge}
                    as="h5"
                    color={ThemeColorVariable.OnBackgroundHeader}
                >
                    {t("errorInformation.serverErrror")}
                </Heading>
                <Text lineHeight="27px" fontSize={ThemeFontSizeVariable.Large} textAlign="center">
                    {t("errorInformation.serverLost")}
                </Text>
                <Flex justifyContent="center">
                    <Button variant="primary" onClick={handleRetry}>
                        {t("errorInformation.tryAgain")}
                    </Button>
                </Flex>
            </Box>
        </Box>
    );
}
