import { ScaleFade } from "@chakra-ui/react";
import {
    Box,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Switch,
    ThemeColorVariable,
} from "caspeco-casper-ui";
import { validateCustomerName } from "helpers";
import { useBookingViewStore } from "hooks";
import { ChangeContactField, ChangeModelField } from "old/actions/webBookingViewActions";
import { ChangeEvent } from "react";
import { Control, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IContactInformationForm } from "./contact-information";

interface ICustomerNameFieldProps {
    control: Control<IContactInformationForm, any>;
}

const CustomerNameField = ({ control }: ICustomerNameFieldProps) => {
    const { t } = useTranslation();
    const { createParametersModel } = useBookingViewStore();
    const { contact, isCompanyCustomer } = createParametersModel;

    const {
        fieldState: { error },
        field,
    } = useController({
        name: "customerName",
        control,
        rules: {
            required: isCompanyCustomer ? t("customer.customerName") : false,
            validate: isCompanyCustomer ? (value) => validateCustomerName(value) : undefined,
        },
    });

    const handleCustomerTypeToggle = (e: React.ChangeEvent<HTMLInputElement>) =>
        new ChangeModelField("isCompanyCustomer", (e.target as HTMLInputElement).checked);

    const handleCustomerNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        field.onChange(e.target.value);
        new ChangeContactField("customerName", value);
    };

    return (
        <>
            <FormControl>
                <Flex direction="row" align="center">
                    <Switch
                        isChecked={isCompanyCustomer}
                        onChange={handleCustomerTypeToggle}
                        id="isCompanyCustomer"
                        color="primary"
                    />
                    <Box ml="10px" pt="5px">
                        <FormLabel>{t("customer.addCustomerName")}</FormLabel>
                    </Box>
                </Flex>
            </FormControl>
            {isCompanyCustomer && (
                <ScaleFade in={isCompanyCustomer}>
                    <FormControl isInvalid={!!error}>
                        <FormLabel>
                            {t("customer.name")}{" "}
                            <Box as="span" color={ThemeColorVariable.Error}>
                                *
                            </Box>
                        </FormLabel>
                        <Input
                            size="lg"
                            id="customerName"
                            name={field.name}
                            autoComplete="customerName"
                            onChange={handleCustomerNameChange}
                            value={contact.customerName}
                            onBlur={field.onBlur}
                            ref={field.ref}
                        />
                        <FormErrorMessage>{error?.message}</FormErrorMessage>
                    </FormControl>
                </ScaleFade>
            )}
        </>
    );
};

export default CustomerNameField;
