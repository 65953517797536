import { useCancelBooking, useWebBooking } from "api/apiHooks";
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalHeader,
    Text,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
    useDisclosure,
} from "caspeco-casper-ui";
import Spinner from "components/spinner/spinner";
import { useBookingIdParam, useExternalBookingSettings } from "hooks";
import moment from "moment";
import BookingStatusEnum from "old/models/bookingStatusEnum";
import { BookingSuccess } from "pages/booking-details/booking-status/booking-success";
import { CancelBookingFailed } from "pages/booking-details/cancel-booking/cancel-booking-failed";
import { CancelBookingSuccess } from "pages/booking-details/cancel-booking/cancel-booking-success";
import Greeting from "pages/booking-details/cancel-booking/greeting";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { WaitListSuccess } from "./wait-list-success";
import GreetingWrapper from "./greeting-wrapper";
import WaitListOffer from "./wait-list-offer";

interface BookingStatusProps {
    isFinal: boolean;
    isCanceled: boolean;
    isSuccess: boolean;
    setNotPossibleToCancel: (value: boolean) => void;
}

const BookingStatus: React.FC<BookingStatusProps> = ({
    isFinal,
    isCanceled,
    isSuccess,
    setNotPossibleToCancel,
}) => {
    const webBooking = useWebBooking().data;
    const externalBookingSettings = useExternalBookingSettings();
    const bookingId = useBookingIdParam();
    const { isLoading, isError } = useCancelBooking(bookingId);
    const { t } = useTranslation();
    const { isOpen, onClose, onOpen } = useDisclosure();

    const guests = webBooking?.guests;
    const date = webBooking?.start.format("dddd D MMMM");
    const startTime = webBooking?.start.format("HH:mm");
    const endTime = webBooking?.end.format("HH:mm");

    useEffect(() => {
        if (webBooking?.cancelAllowedBeforeUtc < moment().utc() && !webBooking.isWaitList()) {
            setNotPossibleToCancel(true);
        }
    }, [webBooking?.cancelAllowedBeforeUtc, setNotPossibleToCancel, webBooking]);

    if (isFinal && webBooking?.isWaitList()) {
        return <WaitListSuccess />;
    }
    if (isFinal && webBooking?.status !== BookingStatusEnum.Canceled) {
        return <BookingSuccess />;
    }
    if (isSuccess && isCanceled && webBooking?.status !== BookingStatusEnum.Canceled) {
        return <CancelBookingSuccess isWaitList={webBooking.isWaitList()} />;
    }
    if (webBooking?.status === BookingStatusEnum.Canceled && webBooking.isWaitList()) {
        return (
            <GreetingWrapper name={webBooking?.contact.fname}>
                <Text fontSize={ThemeFontSizeVariable.Large}>
                    {t("waitList.waitListIsCanceled")}
                </Text>
            </GreetingWrapper>
        );
    }
    if (webBooking?.isBookingCanceled()) {
        return (
            <GreetingWrapper name={webBooking?.contact.fname}>
                <Text fontSize={ThemeFontSizeVariable.Large}>
                    {t("bookingIsCanceled", { guests, date, startTime, endTime })}
                </Text>
            </GreetingWrapper>
        );
    }
    if (webBooking?.hasBookingPassed()) {
        return (
            <GreetingWrapper name={webBooking?.contact.fname}>
                <Text fontSize={ThemeFontSizeVariable.Large}>
                    {t("theTimeHasPassed", { date })}
                </Text>
            </GreetingWrapper>
        );
    }
    if (webBooking.cancelNotAllowed()) {
        return (
            <>
                <GreetingWrapper name={webBooking?.contact.fname}>
                    <Text fontSize={ThemeFontSizeVariable.Large}>
                        <Trans i18nKey="notPossibleToCancelBooking">
                            {t("notPossibleToCancelBooking")}
                            <Button variant="link" onClick={onOpen} />
                        </Trans>
                    </Text>
                </GreetingWrapper>
                <Modal isOpen={isOpen} onClose={onClose} size="md">
                    <ModalHeader
                        display="flex"
                        justifyContent="space-between"
                        p={ThemeSpaceVariable.Medium}
                    >
                        <Text fontWeight="medium" fontSize={ThemeSpaceVariable.Large}>
                            {t("bookingConditions")}
                        </Text>
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody display="flex" flexDirection="column" p={ThemeSpaceVariable.Medium}>
                        {externalBookingSettings.webConditions}
                    </ModalBody>
                </Modal>
            </>
        );
    }

    if (webBooking?.hasWaitListOffers()) {
        return <WaitListOffer />;
    }

    if (isLoading) {
        return <Spinner />;
    }
    if (isError) {
        return <CancelBookingFailed />;
    }
    return <Greeting name={webBooking?.contact.fname} isWaitList={webBooking.isWaitList()} />;
};

export default BookingStatus;
