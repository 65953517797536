import {
    ThemeFontSizeVariable,
    ThemeFontVariable,
    ThemeSpaceVariable,
    Box,
    Button,
    Flex,
    Heading,
    Text,
    Icon,
    Icons,
} from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export default function SomethingWrong() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { systemId, unitId } = useParams(); // Capture systemId and unitId from the URL

    if (systemId && unitId) {
        localStorage.setItem("lastKnownSystemId", systemId);
        localStorage.setItem("lastKnownUnitId", unitId);
    }

    const storedSystemId = localStorage.getItem("lastKnownSystemId");
    const storedUnitId = localStorage.getItem("lastKnownUnitId");
    const validStoredValues =
        storedSystemId && storedUnitId && storedSystemId !== "null" && storedUnitId !== "null";

    const handleRefresh = () => {
        if (validStoredValues) {
            navigate(`/${storedSystemId}/${storedUnitId}`);
            location.reload();
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={ThemeSpaceVariable.Medium}
        >
            <Icon icon={Icons.SlightlySad} color="#242236" fontSize="60px" />
            <Box display="flex" flexDirection="column" gap={ThemeSpaceVariable.Small}>
                <Heading
                    fontWeight="medium"
                    fontFamily={ThemeFontVariable.SubHeader}
                    fontSize={ThemeFontSizeVariable.XLarge}
                    as="h5"
                >
                    {t("errorInformation.somethingWrong")}
                </Heading>
                <Text lineHeight="27px" fontSize={ThemeFontSizeVariable.Large}>
                    {t("errorInformation.errorMessageWrong")}
                </Text>
                {validStoredValues && (
                    <Flex justifyContent="center">
                        <Button variant="primary" onClick={handleRefresh}>
                            {t("errorInformation.tryAgain")}
                        </Button>
                    </Flex>
                )}
            </Box>
        </Box>
    );
}
