import moment from "moment";
import { RecordFactory } from "old/common/modules/recordFactory";
import BookingStatusEnum from "./bookingStatusEnum";

interface IBookingChange {
    changeDate: IMoment;
    status: BookingStatusEnum;
}

const BookingChangeRecord = RecordFactory<IBookingChange>({
    changeDate: moment.invalid(),
    status: -1,
});

export class BookingChange extends BookingChangeRecord {}
