import {
    Box,
    Heading,
    Icon,
    Icons,
    Text,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeFontVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";

export default function BrokenLink() {
    const { t } = useTranslation();

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={ThemeSpaceVariable.Medium}
        >
            <Icon icon={Icons.LinkBroken} color="#242236" fontSize="60px" />
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={ThemeSpaceVariable.Small}
            >
                <Heading
                    fontWeight="medium"
                    fontFamily={ThemeFontVariable.SubHeader}
                    fontSize={ThemeFontSizeVariable.XLarge}
                    as="h5"
                    color={ThemeColorVariable.OnBackgroundHeader}
                >
                    {t("errorInformation.brokenLink")}
                </Heading>
                <Text lineHeight="27px" fontSize={ThemeFontSizeVariable.Large} textAlign="center">
                    {t("errorInformation.brokenLinkText")}
                </Text>
            </Box>
        </Box>
    );
}
