import {
    Button,
    Text,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import React from "react";
import { useTranslation } from "react-i18next";

interface IPrivacyPolicyTextProps {
    onOpen: () => void;
}

const PrivacyPolicyText = ({ onOpen }: IPrivacyPolicyTextProps) => {
    const { t } = useTranslation();

    const handlePolicyClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onOpen();
    };
    return (
        <Text
            mb={ThemeSpaceVariable.Medium}
            color={ThemeColorVariable.OnSurfaceSubdued}
            fontSize={ThemeFontSizeVariable.Small}
        >
            {t("beforePolicy")}{" "}
            <Button
                textDecoration="underline"
                onClick={handlePolicyClicked}
                variant="link"
                size="sm"
                color={ThemeColorVariable.OnSurfaceSubdued}
            >
                {t("privacyPolicy")}
            </Button>
        </Text>
    );
};

export default PrivacyPolicyText;
