import {
    Modal,
    ModalHeader,
    ThemeSpaceVariable,
    ThemeFontSizeVariable,
    ModalCloseButton,
    ModalBody,
    Button,
    Text,
} from "caspeco-casper-ui";
import { useWebBooking } from "hooks";
import { t } from "i18next";
import React from "react";

interface ICancelModal {
    isOpen: boolean;
    handleCancelModalModalClose: () => void;
    handleCancelModalCancel: () => void;
}

const CancelModal = ({
    isOpen,
    handleCancelModalModalClose,
    handleCancelModalCancel,
}: ICancelModal) => {
    const { data: webBooking } = useWebBooking();

    const isWaitList = webBooking.isWaitList();
    return (
        <Modal isOpen={isOpen} onClose={handleCancelModalModalClose} size="md">
            <ModalHeader
                display="flex"
                justifyContent="space-between"
                p={ThemeSpaceVariable.Medium}
            >
                <Text fontWeight="medium" fontSize={ThemeSpaceVariable.Large}>
                    {isWaitList ? t("waitList.waitListBookingCancel") : t("confirmBookingCancel")}
                </Text>
                <ModalCloseButton />
            </ModalHeader>
            <ModalBody display="flex" flexDirection="column" p={ThemeSpaceVariable.Medium}>
                <Text
                    fontSize={ThemeFontSizeVariable.Large}
                    mt={ThemeSpaceVariable.XSmall}
                    mb={ThemeSpaceVariable.Large}
                    fontWeight="500"
                >
                    {isWaitList ? t("waitList.waitListAreYouSureCancel") : t("areYouSureCancel")}
                </Text>

                <Button
                    variant="primary"
                    size="md"
                    alignSelf="flex-end"
                    onClick={handleCancelModalCancel}
                >
                    {isWaitList ? t("waitList.waitListBookingCancel") : t("confirmBookingCancel")}
                </Button>
            </ModalBody>
        </Modal>
    );
};

export default CancelModal;
