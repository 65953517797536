import { List } from "immutable";
import moment from "moment";
import { BookedStatus, DayState } from "old/models/dayState";
import webBookingStore, { IWebBookingState } from "old/stores/webBookingStore";
import { useEffect, useMemo, useState } from "react";
import { StateValue } from "utils";
import { dayStatesToUniqueDates, getBookedStatusByGuestCount } from "../utils/booking";
import { useBookingViewStore } from "./bookingView";

type MonthDayState = {
    day: string;
    open: boolean;
};

export function useBookingStore() {
    const [state, setState] = useState<IWebBookingState>(webBookingStore.getState());

    useEffect(() => {
        const onStoreChange = () => {
            setState(webBookingStore.getState());
        };
        webBookingStore.onChange(onStoreChange);
        return () => {
            webBookingStore.offChange(onStoreChange);
        };
    }, []);
    return state;
}

export function useDayStates() {
    const { webBookingDayStatesMap } = useBookingStore();
    return webBookingDayStatesMap;
}

export function useDayStatesByMonth(month: IMoment) {
    const getMonthKey = (date: IMoment): string => `ds:${date.year()}_${date.month() + 1}`;
    const { viewedMonthDayStates } = useBookingViewStore();

    return viewedMonthDayStates.get(getMonthKey(month)) || List();
}

export function useClosedDays(month: Date) {
    const dayStates = useDayStatesByMonth(moment(month));
    const closedDays: Date[] = [];

    const uniqueDates: MonthDayState[] = getUniqueDates(dayStates);
    setOpenStatus(uniqueDates, dayStates);
    addClosedDays(uniqueDates, closedDays);

    return closedDays;
}

function getUniqueDates(month: List<DayState>): MonthDayState[] {
    const uniqueDates: MonthDayState[] = [];
    const uniqueDaysSet: Set<string> = new Set();

    month.forEach((dayState: DayState) => {
        const formattedDay = dayState.day.format("YYYY-MM-DD");
        if (!uniqueDaysSet.has(formattedDay)) {
            uniqueDaysSet.add(formattedDay);
            uniqueDates.push({ day: formattedDay, open: false });
        }
    });

    return uniqueDates;
}

function setOpenStatus(uniqueDates: MonthDayState[], month: List<DayState>): void {
    month.forEach((dayState: DayState) => {
        const formattedDay = dayState.day.format("YYYY-MM-DD");
        const date = uniqueDates.find((date) => date.day === formattedDay);

        if (date && dayState.state === StateValue.Open) {
            date.open = true;
        }
    });
}

function addClosedDays(uniqueDates: MonthDayState[], closedDays: Date[]): void {
    uniqueDates.forEach((date: MonthDayState) => {
        if (!date.open) {
            closedDays.push(new Date(date.day));
        }
    });
}

export function useWaitlistDays(month: Date, nrOfGuests: number) {
    const dayStates = useDayStatesByMonth(moment(month));
    const today = moment();
    let waitlistDays: Date[] = [];
    dayStatesToUniqueDates(dayStates).forEach((date: Date) => {
        const m = moment(date);
        getBookedStatusByGuestCount(dayStates, m, nrOfGuests) === BookedStatus.WaitList &&
            waitlistDays.push(date);
    });

    waitlistDays = waitlistDays.filter((date) => moment(date).month() === month.getMonth());
    waitlistDays = waitlistDays.filter((date) => moment(date).isSameOrAfter(today, "day"));

    return waitlistDays;
}

export function useFullyBookedDays(month: Date, nrOfGuests: number) {
    const dayStates = useDayStatesByMonth(moment(month));

    const today = moment();
    let fullyBookedDays: Date[] = [];
    dayStatesToUniqueDates(dayStates).forEach((date: Date) => {
        const m = moment(date);
        getBookedStatusByGuestCount(dayStates, m, nrOfGuests) === BookedStatus.FullyBooked &&
            fullyBookedDays.push(date);
    });

    fullyBookedDays = fullyBookedDays.filter((date) => moment(date).month() === month.getMonth());
    fullyBookedDays = fullyBookedDays.filter((date) => moment(date).isSameOrAfter(today, "day"));

    return fullyBookedDays;
}

export function useFirstAvailableDay(): DayState | undefined {
    const dayStates = useDayStates();
    const firstAvailableDay = useMemo(() => {
        const listOfLists = dayStates.valueSeq();
        const flatList = listOfLists.flatten() as List<DayState>;
        const availableDays = flatList.filter(
            (d: DayState) => d.bookedStatus === BookedStatus.Available
        ) as List<DayState>;

        return availableDays.minBy((dayState: DayState) => dayState.day);
    }, [dayStates]);

    return firstAvailableDay as DayState | undefined;
}

export function useHasAvailableDaysForMonth(month: Date): boolean {
    const dayStates = useDayStatesByMonth(moment(month));

    const startOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
    const endOfMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0);

    const availableDaysInMonth = dayStates.filter((d: DayState) => {
        return (
            d.bookedStatus === BookedStatus.Available &&
            d.day.isBetween(moment(startOfMonth), moment(endOfMonth))
        );
    }) as List<DayState>;

    // Check if there are any available days in the given month
    return availableDaysInMonth.count() > 0;
}
