import { ErrorView } from "pages/error-view";
import BookingDetails from "pages/booking-details/booking-details";
import "translations/config/i18n";

import BookingWizard from "pages/booking-wizard";

const routes = [
    {
        path: "/",
        errorElement: <ErrorView />,
        children: [
            {
                path: "/booking/:systemId/:bookingId",
                element: <BookingDetails />,
            },
            {
                path: "/:systemId/:unitId",
                element: <BookingWizard />,
            },
        ],
    },
];
export default routes;
