import { Button } from "caspeco-casper-ui";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

interface ISubmitButtonProps {
    isDisabled: boolean;
    isLoading: boolean;
    isWaitList: boolean;
}

const SubmitButton = ({ isDisabled, isLoading, isWaitList }: ISubmitButtonProps) => {
    const { t } = useTranslation();
    return (
        <Button
            size="lg"
            variant="primary"
            type="submit"
            isLoading={isLoading}
            isDisabled={isDisabled}
            w="100%"
        >
            {isWaitList ? t("waitList.signUpWaitList") : t("book")}
        </Button>
    );
};

export default SubmitButton;
