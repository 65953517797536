import { List } from "immutable";
import { Moment } from "moment";
import { BookedStatus, DayState } from "../old/models/dayState";
import { WebBookingTimeRuleUnit } from "../old/models/webBookingTimeRuleUnit";

export const dayStatesToUniqueDates = (dayStates: List<DayState>): Date[] => {
    const uniqueDates: Date[] = [];
    const uniqueDaysSet: Set<string> = new Set();

    dayStates.forEach((dayState: DayState) => {
        const formattedDay = dayState.day.format("YYYY-MM-DD");
        if (!uniqueDaysSet.has(formattedDay)) {
            uniqueDaysSet.add(formattedDay);
            uniqueDates.push(dayState.day.toDate());
        }
    });

    return uniqueDates;
};

export const getDayStatesByDate = (dayStates: List<DayState>, date: Moment): List<DayState> => {
    const matchingDayStates: DayState[] = [];

    dayStates.forEach((dayState: DayState) => {
        if (dayState.day.isSame(date, "day")) {
            matchingDayStates.push(dayState);
        }
    });

    return List(matchingDayStates);
};

// This method only checks the dayState.bookedStatus, it does not check the number of available seats
export const getBookedStatusByDate = (dayStates: List<DayState>, date: Moment): BookedStatus => {
    // grab all dayStates that matches the date
    const matchingDayStates = getDayStatesByDate(dayStates, date);

    // If no seats are available, return FullyBooked
    const noSeatsAvailable = matchingDayStates.every(
        (dayState) => dayState.bookedStatus === BookedStatus.FullyBooked
    );

    // If no seats are available, but there are waitlist seats, return WaitList
    const hasWaitlistSeats = matchingDayStates.some(
        (dayState) => dayState.bookedStatus === BookedStatus.WaitList
    );

    // If there are available seats, return Available
    const hasAvailableSeats = matchingDayStates.some(
        (dayState) => dayState.bookedStatus === BookedStatus.Available
    );

    if (noSeatsAvailable && !hasWaitlistSeats) {
        return BookedStatus.FullyBooked;
    }
    if (hasWaitlistSeats) {
        return BookedStatus.WaitList;
    }

    if (hasAvailableSeats) {
        return BookedStatus.Available;
    }

    return BookedStatus.Undefined;
};

// This method checks dayState.availableSeats and dayState.waitlistSeats
export const getBookedStatusByGuestCount = (
    dayStates: List<DayState>,
    date: Moment,
    nrOfGuests: number
): BookedStatus => {
    const matchingDayStates = getDayStatesByDate(dayStates, date);

    const hasAvailableSeats = matchingDayStates.some(
        (dayState) => dayState.availableSeats && dayState.availableSeats.includes(nrOfGuests)
    );

    const hasWaitlistSeats = matchingDayStates.some((dayState) => {
        const hasWaitList = !!dayState.waitListSeats;
        if (hasWaitList) {
            return dayState.waitListSeats.includes(nrOfGuests);
        }
        return false;
    });

    const allIsUndefined = matchingDayStates.every(
        (dayState) => dayState.bookedStatus === BookedStatus.Undefined
    );

    if (hasAvailableSeats) {
        return BookedStatus.Available;
    }

    if (hasWaitlistSeats) {
        return BookedStatus.WaitList;
    }

    if (allIsUndefined) {
        return BookedStatus.Undefined;
    }

    return BookedStatus.FullyBooked;
};

export const groupedTimes = (times: List<WebBookingTimeRuleUnit>) =>
    times.reduce((acc: WebBookingTimeRuleUnit, time) => {
        if (time.unavailability !== null || !time.disabled) {
            const hour = time.start.format("HH");
            if (!acc[hour]) {
                acc[hour] = [];
            }
            acc[hour].push(time);
        }
        return acc;
    }, {} as WebBookingTimeRuleUnit);

export const getSortedHourKeys = (hours: string[]) =>
    hours.sort((a, b) => {
        // TODO: Change this to the actual opening hour
        const openingHour = 6;
        const numA = parseInt(a, 10);
        const numB = parseInt(b, 10);

        // Compare hours taking into account the opening hour
        if (numA < openingHour && numB >= openingHour) {
            return 1;
        }
        if (numB < openingHour && numA >= openingHour) {
            return -1;
        }
        return numA - numB;
    });
