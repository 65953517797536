import { Button } from "caspeco-casper-ui";
import { t } from "i18next";
import { WebBooking } from "old/models/webBooking";
import React from "react";
import { getUrlParams } from "utils";

interface IBookMoreButtonProps {
    unitId: number;
    webBooking: WebBooking;
}

const BookMoreButton = ({ unitId, webBooking }: IBookMoreButtonProps) => {
    const { system } = getUrlParams();
    const offerLocked = webBooking.isWaitListOfferLocked() && !webBooking.hasBookingPassed();
    const alignment = offerLocked ? "center" : "flex-end";
    return (
        <Button
            variant="tertiary"
            alignSelf={alignment}
            href={`${window.location.origin}/${system}/${unitId}`}
        >
            {offerLocked ? t("toBookingStart") : t("bookMore")}
        </Button>
    );
};

export default BookMoreButton;
