import WebBookingStepsEnum from "old/models/webBookingStepsEnum";
import moment from "moment-timezone";

export enum BookingEventType {
    ChangeStep,
    ChangeDate,
    SelectTime,
    FinalizeWebBooking,
}

class BookingBaseEvent {
    public readonly eventTime: IMoment;
    constructor() {
        this.eventTime = moment();
    }
}

export class SelectedTime {
    constructor(
        public readonly time: IMoment,
        public readonly sectionName: string,
        public readonly webRuleTitle: string
    ) {}
}

export class ChangeStepEvent extends BookingBaseEvent {
    readonly eventType = BookingEventType.ChangeStep;
    constructor(
        public readonly step: WebBookingStepsEnum,
        public readonly selectedTimes: SelectedTime[]
    ) {
        super();
    }
}

export class ChangeDateEvent extends BookingBaseEvent {
    readonly eventType = BookingEventType.ChangeDate;
    constructor(public readonly date: IMoment) {
        super();
    }
}

export class SelectTimeEvent extends BookingBaseEvent {
    readonly eventType = BookingEventType.SelectTime;
    constructor(
        public readonly time: IMoment,
        public readonly sectionName: string,
        public readonly webRuleTitle: string
    ) {
        super();
    }
}

export class WebBookingEventContact {
    constructor(
        public readonly fistname: string,
        public readonly lastname: string,
        public readonly email: string,
        public readonly mobile: string,
        public readonly acceptsNewsletter: boolean,
        public readonly acceptsBeingRemembered: boolean
    ) {}
}

export class FinalizeWebBookingEvent extends BookingBaseEvent {
    readonly eventType = BookingEventType.FinalizeWebBooking;
    constructor(
        public readonly createdDate: IMoment,
        public readonly bookingDate: IMoment,
        public readonly guest: "returning" | "new",
        public readonly guestCount: number,
        public readonly guestCountAdults: number,
        public readonly guestCountChildren: number,
        public readonly contact: WebBookingEventContact,
        public readonly bookingActivityTypes: string[],
        public readonly timeSinceFirstEvent: number
    ) {
        super();
    }
}

export type BookingEvent =
    | ChangeStepEvent
    | ChangeDateEvent
    | SelectTimeEvent
    | FinalizeWebBookingEvent;
