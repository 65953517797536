import { List, Map } from "immutable";
import moment from "moment-timezone";
import * as ArticleStockActions from "old/actions/articleStockActions";
import * as BookingMenuActions from "old/actions/bookingMenuExternalActions";
import * as WebBookingActionCreators from "old/actions/webBookingActionCreators";
import { reserveWebBooking } from "old/actions/webBookingActionCreators";
import * as WebBookingActions from "old/actions/webBookingActions";
import * as Actions from "old/actions/webBookingViewActions";
import BookingMenuAPI from "old/api/bookingMenuExternalAPI";
import { DateFormat, dateFormatString } from "old/common/mixins/localeHelper";
import { ValidationResult } from "old/common/models/validation/validationResult";
import IStore from "old/framework/istore";
import { Result } from "old/framework/models/result";
import Store from "old/framework/stores/store";
import { ArticleStock, ArticleStockType } from "old/models/articleStock";
import { BookingMenuExternal } from "old/models/bookingMenuExternal";
import BookingMenuGroupType from "old/models/bookingMenuExternalGroupTypeEnum";
import BookingMenuStatusEnum from "old/models/bookingMenuExternalStatusEnum";
import {
    default as BookingStatus,
    default as BookingStatusEnum,
} from "old/models/bookingStatusEnum";
import ChargeStatusEnum from "old/models/chargeStatusEnum";
import ChargeTypeEnum from "old/models/chargeTypeEnum";
import { DayState } from "old/models/dayState";
import { ExternalBookingSettings } from "old/models/externalBookingSettings";
import { MandatoryWebBookingContactFields } from "old/models/mandatoryWebBookingContactFields";
import { WebBooking } from "old/models/webBooking";
import WebBookingAPI, { AvailableTimesTimeSet, AvailableTimesUnit } from "old/models/webBookingAPI";
import { WebBookingCancelParameters } from "old/models/webBookingCancelParameters";
import { WebBookingContact } from "old/models/webBookingContact";
import { WebBookingCreateParameters } from "old/models/webBookingCreateParameters";
import { WebBookingCreateParametersRuleTime } from "old/models/webBookingCreateParametersRuleTime";
import { WebBookingCustomer } from "old/models/webBookingCustomer";
import { WebBookingErrorCodeEnum } from "old/models/webBookingError";
import {
    PaymentMethodType,
    WebBookingPaymentTerminalParameters,
} from "old/models/webBookingPaymentTerminalParameters";
import { WebBookingSection } from "old/models/webBookingSection";
import { WebBookingStep } from "old/models/webBookingStep";
import WebBookingStepStatusEnum from "old/models/webBookingStepStatusEnum";
import WebBookingStepsEnum from "old/models/webBookingStepsEnum";
import { WebBookingTimeRule } from "old/models/webBookingTimeRule";
import { WebBookingTimeRuleUnit } from "old/models/webBookingTimeRuleUnit";
import { WebBookingUnavailability } from "old/models/webBookingUnavailability";
import WebBookingUnavailabilityReason from "old/models/webBookingUnavailabilityReason";
import { WebBookingUnit } from "old/models/webBookingUnit";
import WebTimeRuleBookingTypeEnum from "old/models/webTimeRuleBookingTypeEnum";
import { offsetBetweenTimeZones } from "old/modules/timeHelper";
import BookingMenuStore from "old/stores/bookingMenuExternalStore";
import ExternalBookingSettingsStore from "old/stores/externalBookingSettingsStore";
import WebBookingStore from "old/stores/webBookingStore";
import { getUrlParams } from "utils";

type Summary = {
    date: string;
    guests: number;
    time: string;
    times: { time: string; title: string }[];
};

export type IWebBookingViewStoreState = {
    createParametersModel: WebBookingCreateParameters;
    bookingReservation: WebBooking;
    selectedTimes: List<WebBookingTimeRuleUnit>;
    activeStepOnceValidated: boolean;
    applicableSteps: List<WebBookingStep>;
    activeStep: WebBookingStep;
    activeStepLoading: boolean;
    nextStep: WebBookingStep;
    prevStep: WebBookingStep;
    nextStepRequested: boolean;
    webBookingConditions: List<string>;
    hideCoverImage: boolean;
    selectedSections: List<WebBookingSection>;
    paymentValidationResult: ValidationResult;
    filteredUnitsResult: Result<List<WebBookingUnit>>;
    selectedPaymentMethod: PaymentMethodType;
    webBookingUnitsResult: Result<List<WebBookingUnit>>;
    paymentInformations: List<string>;
    viewedMonthDayStates: Map<string, List<DayState>>;
    fromTimes: List<IMoment>;
    currency: string;
    firstEventTime: IMoment | null;
    date: IMoment;
    unitWithAvailableTimes: WebBookingUnit;
    contactLoadedByFriendlyId: WebBookingContact | null;
};

interface IWebBookingViewStore extends IStore {
    activeStepOnceValidated(): boolean;
    getActiveStep(): WebBookingStep;
    getApplicableSteps(): List<WebBookingStep>;
    getConditions(): List<string>;
    getCreateParametersModel(): WebBookingCreateParameters;
    getCreatedWebBooking(): WebBooking;
    getDate(): IMoment;
    getExternalBookingSettings(): ExternalBookingSettings;
    getFilteredUnitsResult(): Result<List<WebBookingUnit>>;
    getFromTime(): IMoment;
    getFromTimes(): List<IMoment>;
    getInitialStep(): string;
    getLoadedMonthDayStateResult(
        moment: IMoment,
        unitId: number,
        sectionIds: List<number>,
        system: string
    ): Result<List<DayState>>;
    getNextStep(): WebBookingStep;
    getPaymentInformations(): List<string>;
    getPaymentValidationResult(): ValidationResult;
    getPrevStep(): WebBookingStep;
    getSelectedPayMethod(): PaymentMethodType;
    getSelectedSections(): List<WebBookingSection>;
    getSelectedTimes(): List<WebBookingTimeRuleUnit>;
    getSummary(): Summary;
    getSystem(): string;
    getViewedMonthDayStates(): Map<string, List<DayState>>;
    getWebBookingReservation(): WebBooking;
    getWebBookingUnitsResult(): Result<List<WebBookingUnit>>;
    isActiveStepLoading(): boolean;
    isCreatingWebBooking(): boolean;
    isInitialized(): boolean;
    getCurrency(): string;
}

class WebBookingViewStore extends Store implements IWebBookingViewStore {
    private createParametersModel: WebBookingCreateParameters;
    private bookingReservation: WebBooking;
    private paymentValidationResult: ValidationResult;
    private externalBookingSettings: ExternalBookingSettings;
    private createdWebBooking: WebBooking;
    private creatingWebBooking: boolean;
    private webBookingUnitsResult: Result<List<WebBookingUnit>>;
    private viewedMonthDayStates: Map<string, List<DayState>>;
    private filteredUnitsResult: Result<List<WebBookingUnit>>;
    private fromTime: IMoment;
    private fromTimes: List<IMoment>;

    private date: IMoment;
    private unitId: number;
    private sectionIds: List<number>;
    private webBookingId: string;
    private chargeId: string;
    private paymentLink: boolean;
    private system: string;
    private initialStep: string;
    private advanced: boolean;
    private initialized: boolean;
    private selectedTimes: List<WebBookingTimeRuleUnit>;
    private selectedSections: List<WebBookingSection>;
    private activeStep: WebBookingStep;
    //private allSteps: List<WebBookingStepsEnum>; //All possible steps in solution
    private applicableSteps: List<WebBookingStep>; //applicable steps depending on parameters or choosen webRule
    private onlineorder: boolean;
    private paymentMethodType: PaymentMethodType;
    private nextStepRequested: boolean;
    private tz: string;
    private offset: number;
    private customerLoadedByFriendlyId: WebBookingCustomer | null;
    private contactLoadedByFriendlyId: WebBookingContact | null;
    private hideCoverImage: boolean;

    private firstEventTime: IMoment | null;

    constructor() {
        super();

        // Actions
        this.handle(Actions.SetActiveStepLoading.actionType, this.setActiveStepPendingAndEmit);
        this.handle(Actions.ChangeDate.actionType, this.handleChangeDate);
        this.handle(Actions.ChangeFromTime.actionType, this.handleChangeFromTime);
        this.handle(Actions.ChangeSectionFilter.actionType, this.handleChangeSectionFilter);
        this.handle(Actions.ChangeModelField.actionType, this.handleChangeModelField);
        this.handle(Actions.ChangeContactField.actionType, this.handleChangeContactField);
        this.handle(Actions.Initialize.actionType, this.handleInitialize);
        this.handle(Actions.Reset.actionType, this.handleResetStore);
        this.handle(Actions.TimeClicked.actionType, this.handleTimeClicked);
        this.handle(Actions.WaitListTimeClicked.actionType, this.handleSelectMultipleWaitListSlots);
        this.handle(Actions.NextStep.actionType, this.handleNextStep);
        this.handle(Actions.PrevStep.actionType, this.handlePrevStep);
        this.handle(Actions.SetStep.actionType, this.handleSetStep);
        this.handle(Actions.SkipStep.actionType, this.handleSkipStep);
        this.handle(Actions.ChangeMenuField.actionType, this.handleChangeMenuField);
        this.handle(Actions.PostMenu.actionType, this.handlePostMenuClicked);
        this.handle(Actions.CancelBooking.actionType, this.handleCancelBookingClicked);
        this.handle(Actions.ChangePaymentMethod.actionType, this.handleChangePaymentMethod);
        this.handle(Actions.GetPaymentTerminal.actionType, this.handleGetPaymentTerminalClicked);
        this.handle(Actions.ResetActiveStepValidation.actionType, this.resetActiveStepValidation);
        this.handle(Actions.FirstEventTime.actionType, this.handleFirstEventTime);
        this.handle(Actions.ResetSelectedTimes.actionType, this.resetSelectedTimesAndEmit);

        this.handle(
            WebBookingActions.LoadedReservationSuccessfully.actionType,
            this.handleLoadReservationSuccessfully
        );
        this.handle(
            WebBookingActions.LoadReservationFailed.actionType,
            this.handleLoadReservationFailed
        );
        this.handle(
            WebBookingActions.LoadWebBookingFailed.actionType,
            this.handleLoadWebBookingFailed
        );
        this.handle(
            BookingMenuActions.RespondMenuFailed.actionType,
            this.handleRespondBookingMenuFailed
        );
        this.handle(
            BookingMenuActions.UpdatedSuccessfully.actionType,
            this.handleRespondBookingMenuSuccessfully
        );

        this.handle(
            WebBookingActions.LoadedCustomerContactSuccess.actionType,
            this.handleLoadedCustomerContactSuccess
        );
        this.handle(
            WebBookingActions.LoadedCustomerContactFailed.actionType,
            this.handleLoadedCustomerContactFailed
        );

        this.handle(WebBookingActions.FinalizeWebBooking.actionType, this.handleFinalizeWebBooking);
        this.handle(
            WebBookingActions.FinalizeWebBookingFailed.actionType,
            this.handleFinalizeWebBookingFailed
        );
        this.handle(
            WebBookingActions.FinalizeWebBookingSuccess.actionType,
            this.handleWebBookingFinalizedSuccessfully
        );

        this.handle(
            WebBookingActions.ReserveBookingSuccess.actionType,
            this._handleReserveBookingSuccess
        );
        this.handle(
            WebBookingActions.ReserveBookingFail.actionType,
            this._handleReserveBookingFail
        );
        this.handle(
            WebBookingActions.LoadTerminalUrlFailed.actionType,
            this._handleLoadTerminalUrlFailed
        );

        this.handle(WebBookingActions.CancelBooking.actionType, this._handleCancelBooking);
        this.handle(
            WebBookingActions.CancelBookingSuccess.actionType,
            this._handleCancelBookingSuccess
        );
        this.handle(WebBookingActions.CancelBookingFail.actionType, this._handleCancelBookingFail);

        this.handle(
            WebBookingActions.LoadedTerminalUrlSuccessfully.actionType,
            this._handleLoadedTerminalUrl
        );
        this.handle(
            WebBookingActions.BackFromTerminalSuccessfully.actionType,
            this._handleBackFromTerminalSuccessfully
        );
        this.handle(
            WebBookingActions.BackFromTerminalFailed.actionType,
            this._handleBackFromTerminalFailed
        );

        this.handle(
            ArticleStockActions.LoadByArticleIdSuccess.actionType,
            this.handleArticleStocksLoaded
        );

        // StoreChanges
        WebBookingStore.onChange(this.handleWebBookingStoreChanged);
        ExternalBookingSettingsStore.onChange(this.handleExternalBookingSettingsStoreChanged);
        BookingMenuStore.onChange(this.handleBookingMenuStoreChanged);

        // Initial values
        this.createParametersModel = new WebBookingCreateParameters();
        this.externalBookingSettings = new ExternalBookingSettings({});
        this.date = null;
        this.unitId = null;
        this.sectionIds = List<number>();
        this.webBookingId = null;
        this.webBookingUnitsResult = new Result({
            status: this.NotLoaded,
            value: new List<WebBookingUnit>(),
        });
        this.viewedMonthDayStates = Map<string, List<DayState>>();
        this.filteredUnitsResult = new Result({
            status: this.NotLoaded,
            value: List<WebBookingUnit>(),
        });
        this.paymentMethodType = null;

        this.createdWebBooking = null;
        this.creatingWebBooking = false;

        this.fromTime = null;
        this.fromTimes = List();

        this.bookingReservation = new WebBooking();

        this.initialized = false;
        this.selectedSections = List<WebBookingSection>();
        this.selectedTimes = List<WebBookingTimeRuleUnit>();
        this.activeStep = new WebBookingStep({});
        this.applicableSteps = List<WebBookingStep>();

        this.hideCoverImage = false;

        this.firstEventTime = null;
    }

    handleFirstEventTime() {
        this.firstEventTime = moment();
        this.emitChange();
    }

    handleLoadedCustomerContactSuccess = (
        _action: WebBookingActions.LoadedCustomerContactSuccess
    ) => {
        this.createParametersModel = this.createParametersModel
            .set("isCompanyCustomer", true)
            .set("wishToBeInvoiced", true);

        if (_action.webBookingContactCustomerConnection.contact) {
            this.createParametersModel = this.createParametersModel.set(
                "contact",
                _action.webBookingContactCustomerConnection.contact
            );
            this.contactLoadedByFriendlyId = _action.webBookingContactCustomerConnection.contact;
        }
        if (_action.webBookingContactCustomerConnection.customer) {
            this.createParametersModel = this.createParametersModel.set(
                "customer",
                _action.webBookingContactCustomerConnection.customer
            );
            this.customerLoadedByFriendlyId = _action.webBookingContactCustomerConnection.customer;
        }

        this.emitChange();
    };

    handleLoadedCustomerContactFailed = (
        _action: WebBookingActions.LoadedCustomerContactFailed
    ) => {
        this.applicableSteps = List<WebBookingStep>([
            new WebBookingStep({ step: WebBookingStepsEnum.LinkError }),
        ]);
        this.setNextStep();
        this.creatingWebBooking = false;
        this.emitChange();
    };

    private handleFinalizeWebBooking = (_action: WebBookingActions.FinalizeWebBooking) => {
        this.creatingWebBooking = true;
        this.setActiveStepPending();
        this.emitChange();
    };

    private handleFinalizeWebBookingFailed = (
        _action: WebBookingActions.FinalizeWebBookingFailed
    ) => {
        this.applicableSteps = List<WebBookingStep>([
            new WebBookingStep({ step: WebBookingStepsEnum.Error }),
        ]);
        this.setNextStep();
        this.creatingWebBooking = false;
        this.emitChange();
    };

    private handleWebBookingFinalizedSuccessfully = (
        action: WebBookingActions.FinalizeWebBookingSuccess
    ) => {
        // this.applicableSteps = List<WebBookingStepRecord>([
        //   new WebBookingStep({ step: WebBookingStepsEnum.Final })
        // ]);
        const menus = this.bookingReservation.menus;
        this.bookingReservation = <WebBooking>action.webBooking.set("menus", menus);
        this.webBookingId = action.webBooking.guid;
        //this.setApplicableSteps();
        this.setNextStep();
        this.creatingWebBooking = false;
        this.createdWebBooking = action.webBooking;
        this.emitChange();
    };

    private setFromTime = () => {
        const now = moment.tz(this.getUnitTimezone());
        if (this.date.isSame(now, "day")) {
            this.fromTime =
                this.fromTimes.find((t) => t.isAfter(now) || now.diff(t, "minutes") < 60) ||
                this.fromTimes.last();
        } else {
            this.fromTime = this.fromTimes.first();
        }
    };

    private momentHourRange(min: IMoment, max: IMoment): List<IMoment> {
        if (min.isAfter(max)) throw new Error("min > max");

        const result: IMoment[] = [];
        const diff = Math.max(max.diff(min, "hours"), 1);

        let m = moment(min).minute(0);

        for (let i = 0; i < diff; i++) {
            m = i === 0 ? m : m.clone().add(1, "hours");
            result.push(m);
        }

        return List(result);
    }

    private getMinTime(times: List<IMoment>) {
        return times.min((m1, m2) => (m1.isBefore(m2) ? -1 : 1));
    }

    private getMaxTime(times: List<IMoment>) {
        return times.max((m1, m2) => (m1.isAfter(m2) ? 1 : -1));
    }

    private setFromTimes = () => {
        const times = this.extractTimesFromUnits(this.webBookingUnitsResult.value);
        if (times.count() === 0) {
            throw new Error("no timed extracted in webBookingViewStore.setFromTimes");
        }
        const minTime = this.getMinTime(times);
        const maxTime = this.getMaxTime(times);

        this.fromTimes = this.momentHourRange(minTime, maxTime);
    };

    private handleWebBookingStoreChanged = () => {
        if (this.date) {
            this.webBookingUnitsResult = WebBookingStore.getWebBookingUnitsResult(
                List<IMoment>([this.date]),
                List<number>([this.unitId]),
                this.sectionIds
            );
        }

        this.viewedMonthDayStates = WebBookingStore.getViewedMonthDayStates();

        if (this.date && this.webBookingUnitsResult.status === this.Loaded) {
            try {
                this.setFromTimes();
                this.setFromTime();
            } catch (Ex) {
                // reset these if input data was not sufficient to calculate them
                this.fromTime = null;
                this.fromTimes = List();
            }
            this.resetSelectedTimes();

            const sections = this.webBookingUnitsResult.value.flatMap((u) => u.sections).toList();
            const noSelectedSectionsOrMissingSection =
                !this.selectedSections ||
                this.selectedSections.count() === 0 ||
                this.selectedSections.some((ss) => !sections.some((s) => s.id === ss.id));

            if (noSelectedSectionsOrMissingSection) {
                this.selectedSections = sections;
            } else {
                this.selectedSections = sections
                    .filter((s) => this.selectedSections.some((ss) => s.id === ss.id))
                    .toList();
            }

            if (this.onlineorder) {
                if (noSelectedSectionsOrMissingSection) {
                    this.selectedSections = List([sections.sortBy((x) => x.id).first()]);
                }

                this.filterUnitsResult();
                const time = this.getFirstSelectableTime(this.filteredUnitsResult.value);
                if (time) {
                    this.selectedTimes = List([time]);
                    this.createParametersModel = this.syncTimesWithModel();
                }
            } else {
                this.filterUnitsResult();
            }
        }

        if (this.webBookingId) {
            const result = WebBookingStore.getWebBookingResult(this.webBookingId, this.system);
            this.bookingReservation = result.value;

            // Load article stocks for display in the menu
            if (this.activeStep.step === WebBookingStepsEnum.Menu) {
                WebBookingActionCreators.loadArticleStocks(this.bookingReservation, this.system);
            }

            // Reset contactinformation from booking
            if (
                !this.createParametersModel ||
                this.createParametersModel.contact?.name.length < 1
            ) {
                const existingContact = new WebBookingContact({
                    mobile: this.bookingReservation.contact.mobile,
                    fname: this.bookingReservation.contact.fname,
                    lname: this.bookingReservation.contact.lname,
                    email: this.bookingReservation.contact.email,
                    streetaddress: this.bookingReservation.contact.streetaddress,
                    zipcode: this.bookingReservation.contact.zipcode,
                    city: this.bookingReservation.contact.city,
                    deliveryInfo: this.bookingReservation.contact.information,
                });
                this.createParametersModel = this.createParametersModel.set(
                    "contact",
                    existingContact
                );
            }

            this.setApplicableSteps();
            if (this.bookingReservation.unitId && this.bookingReservation.unitId > 0) {
                this.externalBookingSettings =
                    ExternalBookingSettingsStore.getExternalBookingSettingssResult(
                        this.bookingReservation.unitId,
                        this.system
                    ).value;
            }
            if (!this.isStepNeeded(this.activeStep.step)) {
                this.setNextStep(); //this will go to error if activestep is not needed
            }
        }

        this.emitChange();
    };

    private handleExternalBookingSettingsStoreChanged = () => {
        if (this.bookingReservation.unitId && this.bookingReservation.unitId > 0) {
            this.externalBookingSettings =
                ExternalBookingSettingsStore.getExternalBookingSettingssResult(
                    this.bookingReservation.unitId,
                    this.system
                ).value;
        } else if (this.unitId && this.unitId > 0) {
            this.externalBookingSettings =
                ExternalBookingSettingsStore.getExternalBookingSettingssResult(
                    this.unitId,
                    this.system
                ).value;
            if (this.externalBookingSettings.paymentMethods?.count() === 1) {
                //Preselect the only payment method
                this.paymentMethodType = this.externalBookingSettings.paymentMethods.first();
            } else if (this.externalBookingSettings.paymentMethods?.count() === 0) {
                //Default to card if no paymethods are selected
                this.paymentMethodType = PaymentMethodType.CreditCard;
            }
        }

        this.emitChange();
    };

    private handleBookingMenuStoreChanged = () => {
        this.emitChange();
    };

    private handleLoadWebBookingFailed = () => {
        this.applicableSteps = List<WebBookingStep>([
            new WebBookingStep({ step: WebBookingStepsEnum.LinkError }),
        ]);
        this.setNextStep();
        this.emitChange();
    };

    private handleLoadReservationSuccessfully = (
        action: WebBookingActions.LoadedReservationSuccessfully
    ) => {
        this.bookingReservation = action.webBooking;
        this.emitChange();
    };

    private handleLoadReservationFailed = () => {
        this.applicableSteps = List<WebBookingStep>([
            new WebBookingStep({ step: WebBookingStepsEnum.Error }),
        ]);
        this.setNextStep();
        this.emitChange();
    };

    private handleArticleStocksLoaded = (action: ArticleStockActions.LoadByArticleIdSuccess) => {
        this.bookingReservation.menus.map((m, menuIndex) =>
            m.groups.map((g, groupIndex) =>
                g.items.map((item, itemIndex) => {
                    const articleStock: ArticleStock = action.articleStocks
                        .get(item.articleID)
                        ?.toList()
                        .find((x: ArticleStock) => x.type === ArticleStockType.CurrentValue);
                    if (articleStock && item.quantity > articleStock.quantity) {
                        this.bookingReservation = this.bookingReservation.setIn(
                            [
                                "menus",
                                menuIndex,
                                "groups",
                                groupIndex,
                                "items",
                                itemIndex,
                                "quantity",
                            ],
                            articleStock.quantity
                        );
                        this.bookingReservation = this.bookingReservation.setIn(
                            [
                                "menus",
                                menuIndex,
                                "groups",
                                groupIndex,
                                "items",
                                itemIndex,
                                "wasOutOfStock",
                            ],
                            true
                        );
                    }
                })
            )
        );
    };

    private handleRespondBookingMenuFailed = () => {
        WebBookingActionCreators.loadArticleStocks(this.bookingReservation, this.system);
        this.setPrevStep();
        this.bookingReservation = <WebBooking>this.bookingReservation.set("isInUpdate", false);
        this.emitChange();
    };

    private handleRespondBookingMenuSuccessfully = (
        action: BookingMenuActions.UpdatedSuccessfully
    ) => {
        action.bookingMenus.forEach((updatedMenu) => {
            const menuIndex = this.bookingReservation.menus.findIndex(
                (m) => m.publicId === updatedMenu.publicId
            );
            if (menuIndex > -1) {
                this.bookingReservation = <WebBooking>(
                    this.bookingReservation.setIn(["menus", menuIndex], updatedMenu)
                );
            }

            if (updatedMenu.chargeType !== ChargeTypeEnum.None) {
                //No support for combining noshow booking and prepayment menu
                this.bookingReservation = this.bookingReservation
                    .set("chargeType", ChargeTypeEnum.PrePayment)
                    .set("chargePaymentLinkExpirationDate", moment().add(15, "minute"));
            }

            if (this.bookingReservation.chargeType !== ChargeTypeEnum.NoShow) {
                const chargeShouldPayAmount = this.selectedTimes.some((x) => x.requireMenuPayment)
                    ? this.bookingReservation.chargeShouldPayAmount +
                      BookingMenuExternal.sumOfAmount(this.bookingReservation.menus)
                    : this.bookingReservation.chargeShouldPayAmount;
                this.bookingReservation = this.bookingReservation.set(
                    "chargeShouldPayAmount",
                    chargeShouldPayAmount
                );
            }
        });

        if (this.bookingReservation) {
            this.bookingReservation = <WebBooking>this.bookingReservation.set("isInUpdate", false);
            this.setActiveStepActive();
        }

        if (!action.respondSilent) {
            this.setNextStep();
        }
        this.emitChange();
    };

    private _handleReserveBookingSuccess = (action: WebBookingActions.ReserveBookingSuccess) => {
        const userHasCanceled = this.activeStep.step === WebBookingStepsEnum.Start;
        if (userHasCanceled) {
            const webBookingCancelParameters = new WebBookingCancelParameters({});
            WebBookingAPI.cancelWebBooking(
                action.webBooking.guid,
                webBookingCancelParameters,
                this.system
            );
        }
        this.creatingWebBooking = false;
        this.bookingReservation = action.webBooking;
        this.emitChange();
    };

    private _handleReserveBookingFail = (_action: WebBookingActions.ReserveBookingFail) => {
        if (_action.webBookingError?.code === WebBookingErrorCodeEnum.FullyBooked) {
            this.applicableSteps = List<WebBookingStep>([
                new WebBookingStep({ step: WebBookingStepsEnum.FullyBooked }),
            ]);
        } else {
            this.applicableSteps = List<WebBookingStep>([
                new WebBookingStep({ step: WebBookingStepsEnum.Error }),
            ]);
        }

        this.creatingWebBooking = false;
        this.selectedTimes = List<WebBookingTimeRuleUnit>();
        this.filteredUnitsResult = new Result({ status: this.NotLoaded, value: List([]) });
        this.bookingReservation = new WebBooking();

        this.setActiveStepActive();
        this.setNextStep();
        WebBookingStore.flushData();

        this.emitChange();
    };

    private _handleLoadTerminalUrlFailed = (_action: WebBookingActions.LoadTerminalUrlFailed) => {
        this.applicableSteps = List<WebBookingStep>([
            new WebBookingStep({ step: WebBookingStepsEnum.Error }),
        ]);

        this.creatingWebBooking = false;
        this.selectedTimes = List<WebBookingTimeRuleUnit>();
        this.filteredUnitsResult = new Result({ status: this.NotLoaded, value: List([]) });
        this.bookingReservation = new WebBooking();

        this.setNextStep();
        WebBookingStore.flushData();

        this.emitChange();
    };

    private _handleLoadedTerminalUrl = (
        _action: WebBookingActions.LoadedTerminalUrlSuccessfully
    ) => {
        window.location.replace(_action.terminalUrl);
        window.parent.postMessage("resetHeight", "*");
    };

    private _handleBackFromTerminalSuccessfully = (
        _action: WebBookingActions.BackFromTerminalSuccessfully
    ) => {
        this.bookingReservation = _action.webBooking;
        this.setApplicableSteps();
        this.setNextStep();
        this.emitChange();
    };

    private _handleBackFromTerminalFailed = (_action: WebBookingActions.BackFromTerminalFailed) => {
        this.paymentValidationResult = _action.validationResult;

        if (
            this.paymentValidationResult.generalErrors.find(
                (x) => x.message === "webBooking.paymentErrors.DoubleBookingException"
            )
        ) {
            this.applicableSteps = List<WebBookingStep>([
                new WebBookingStep({ step: WebBookingStepsEnum.DoubleBookingError }),
            ]);
        } else {
            this.applicableSteps = List<WebBookingStep>([
                new WebBookingStep({ step: WebBookingStepsEnum.BookingSummary }),
                new WebBookingStep({ step: WebBookingStepsEnum.PaymentTerminal }),
            ]);
        }

        this.setNextStep();
        this.emitChange();
    };

    private _handleCancelBooking = (_action: WebBookingActions.CancelBooking) => {
        return;
    };

    private _handleCancelBookingSuccess = (_action: WebBookingActions.CancelBookingSuccess) => {
        this.bookingReservation = <WebBooking>this.bookingReservation.set("guid", "");

        if (this.activeStep.step === WebBookingStepsEnum.BookingCancel) {
            this.setNextStep();
        }
        this.emitChange();
    };

    private _handleCancelBookingFail = (_action: WebBookingActions.CancelBookingFail) => {
        new WebBookingStep({ step: WebBookingStepsEnum.PostError }),
            (this.applicableSteps = List<WebBookingStep>([]));
        this.setNextStep();
        this.emitChange();
    };

    private handleInitialize = (action: Actions.Initialize) => {
        this.unitId = action.unitId;
        this.sectionIds = List<number>(action.sectionIds);
        this.webBookingId = action.webBookingId;
        this.chargeId = action.chargeId;
        this.paymentLink = action.paymentLink;
        this.system = action.system;
        this.initialStep = action.initialStep;
        this.advanced = action.advanced;
        this.onlineorder = action.onlineorder;

        this.initialized = true;

        if (this.webBookingId != null && this.system != null) {
            if (this.chargeId != null) {
                this.activeStep = new WebBookingStep({
                    step: WebBookingStepsEnum.PaymentTerminalReturn,
                    status: WebBookingStepStatusEnum.Active,
                });
            } else if (this.initialStep === "cancel") {
                this.bookingReservation = WebBookingStore.getWebBookingResult(
                    this.webBookingId,
                    this.system
                ).value;
                this.activeStep = new WebBookingStep({
                    step: WebBookingStepsEnum.BookingCancel,
                    status: WebBookingStepStatusEnum.Active,
                });
            } else if (this.paymentLink) {
                this.bookingReservation = WebBookingStore.getWebBookingResult(
                    this.webBookingId,
                    this.system
                ).value;
                this.activeStep = new WebBookingStep({
                    step: WebBookingStepsEnum.BookingSummary,
                    status: WebBookingStepStatusEnum.Active,
                });
            } else {
                this.bookingReservation = WebBookingStore.getWebBookingResult(
                    this.webBookingId,
                    this.system
                ).value;
                this.activeStep = new WebBookingStep({
                    step: WebBookingStepsEnum.Menu,
                    status: WebBookingStepStatusEnum.Active,
                });
            }
        }

        if (this.webBookingId == null && this.unitId > 0 && this.system != null) {
            this.externalBookingSettings =
                ExternalBookingSettingsStore.getExternalBookingSettingssResult(
                    this.unitId,
                    this.system
                ).value;
            /*
            const step = false; pathEndsWith("/contact")
                ? WebBookingStepsEnum.ContactInformation
                : WebBookingStepsEnum.Start;
             */
            this.activeStep = new WebBookingStep({
                step: WebBookingStepsEnum.Start,
                status: WebBookingStepStatusEnum.Active,
            });
            this.filterUnitsResult();
        }

        if (this.onlineorder) {
            this.createParametersModel = new WebBookingCreateParameters({ guests: 1 });
        }

        this.setApplicableSteps();
        this.emitChange();
    };

    private handleResetStore = () => {
        this.date = null;
        this.webBookingId = null;
        this.selectedTimes = List<WebBookingTimeRuleUnit>();
        this.bookingReservation = new WebBooking();
        this.createParametersModel = new WebBookingCreateParameters();
        this.activeStep = new WebBookingStep({
            step: WebBookingStepsEnum.Start,
            status: WebBookingStepStatusEnum.Active,
        });
        this.setApplicableSteps();
        this.emitChange();
    };

    private resetSelectedTimes = () => {
        this.selectedTimes = List<WebBookingTimeRuleUnit>();
    };

    private resetSelectedTimesAndEmit = () => {
        this.selectedTimes = List<WebBookingTimeRuleUnit>();
        this.emitChange();
    };

    private handleChangeFromTime = (action: Actions.ChangeFromTime) => {
        this.fromTime = action.fromTime;
        this.resetSelectedTimes();
        this.filterUnitsResult();
        this.emitChange();
    };

    private getFirstSelectableTime(webBookingUnits: List<WebBookingUnit>): WebBookingTimeRuleUnit {
        return webBookingUnits
            .first()
            .sections.first()
            ?.timeSets.filter((ts) => ts.times.count() > 0)
            .first()
            ?.times.filter((time) => !time.disabled)
            .first();
    }

    private handleChangeSectionFilter = (action: Actions.ChangeSectionFilter) => {
        this.selectedSections = action.sections;
        this.filterUnitsResult();
        if (this.onlineorder) {
            const time = this.getFirstSelectableTime(this.filteredUnitsResult.value);
            if (time) {
                this.selectedTimes = List([time]);
                this.createParametersModel = this.syncTimesWithModel();
            } else {
                this.selectedTimes = List();
            }
        }
        this.emitChange();
    };

    private handleChangeDate = (action: Actions.ChangeDate) => {
        if (action.date === null) {
            // We can end up in a state otherwise were webBookingUnitsResult.status === 'loading'
            // It would be better if we could cancel a request
            this.webBookingUnitsResult = new Result({
                status: this.NotLoaded,
                value: new List<WebBookingUnit>(),
            });
        }

        this.date = action.date;
        if (this.date) {
            this.webBookingUnitsResult = WebBookingStore.getWebBookingUnitsResult(
                List<IMoment>([this.date]),
                List<number>([this.unitId]),
                this.sectionIds
            );
        }
        this.resetSelectedTimes();
        if (this.date) {
            WebBookingStore.getLoadedMonthDayStateResult(
                this.date,
                this.unitId,
                this.sectionIds,
                this.system
            );
        }
        // WebBookingStore.getLoadedMonthDayStateResult(moment(this.date).add(1, "month"), this.unitId, this.sectionIds, this.system);
        this.filterUnitsResult();
        this.emitChange();
    };

    private handleNextStep = (action: Actions.NextStep) => {
        this.nextStepRequested = true;
        this.validateActiveStep();
        console.log(this.activeStep.validationResult.isValid(), "validation result next step");

        if (this.activeStep.validationResult.isValid()) {
            if (this.activeStep.step === WebBookingStepsEnum.ContactInformation) {
                this.setActiveStepPending();
                console.log(action);
                action?.onFinalize();
            } else if (
                this.activeStep.step === WebBookingStepsEnum.MenuSummary &&
                this.decideNextStep().step === WebBookingStepsEnum.ContactInformation
            ) {
                //Respond to menu in background
                BookingMenuAPI.respond(
                    <List<BookingMenuExternal>>(
                        this.bookingReservation.menus.filter(
                            (m) => m.status === BookingMenuStatusEnum.Sent
                        )
                    ),
                    this.system,
                    true
                );
                if (this.bookingReservation) {
                    this.bookingReservation = <WebBooking>(
                        this.bookingReservation.set("isInUpdate", true)
                    );
                }
            } else if (
                this.bookingReservation &&
                this.bookingReservation.guid.length > 10 &&
                this.activeStep.step !== WebBookingStepsEnum.Start
            ) {
                // If we have a booking, refresh it. If the refresh fails, ignore it.
                WebBookingAPI.refreshWebBooking(this.bookingReservation.guid, this.system);
            }

            this.setNextStep();
        }
        this.emitChange();
    };

    private handlePrevStep = (_action: Actions.PrevStep) => {
        this.setPrevStep();
        // If we have a booking, refresh it. Cancel if we are going back to the first step
        // If any call fails, ignore it.
        const guid = this.bookingReservation && this.bookingReservation.guid;
        if (guid && guid.length > 10) {
            if (this.activeStep.step === WebBookingStepsEnum.Start) {
                const webBookingCancelParameters = new WebBookingCancelParameters({});
                WebBookingAPI.cancelWebBooking(guid, webBookingCancelParameters, this.system);
            } else {
                WebBookingAPI.refreshWebBooking(guid, this.system);
            }
        }

        this.emitChange();
    };

    private handleSetStep = (action: Actions.SetStep) => {
        const activeStepIndex = this.applicableSteps.findIndex(
            (s) => s.step === this.activeStep.step
        );
        this.applicableSteps = this.applicableSteps.setIn(
            [activeStepIndex, "status"],
            WebBookingStepStatusEnum.Incomplete
        );
        this.activeStep = action.step;
        this.activeStep = <WebBookingStep>(
            this.activeStep.set("status", WebBookingStepStatusEnum.Active)
        );
        this.emitChange();
    };

    private handleSkipStep = (_action: Actions.SkipStep) => {
        if (this.isStepSkippable(this.activeStep.step)) {
            switch (this.activeStep.step) {
                case WebBookingStepsEnum.Menu:
                    // set all selected quantities to 0
                    this.bookingReservation.menus.forEach((menu, menuIndex) => {
                        menu.groups.forEach((group, groupIndex) => {
                            group.items.forEach((item, itemIndex) => {
                                if (item.quantity > 0) {
                                    const itemPath = [
                                        "menus",
                                        menuIndex,
                                        "groups",
                                        groupIndex,
                                        "items",
                                        itemIndex,
                                    ];
                                    const path = itemPath.concat("quantity");
                                    this.bookingReservation = <WebBooking>(
                                        this.bookingReservation.setIn(path, 0)
                                    );
                                }
                            });
                        });
                    });
                    break;
                default:
                    break;
            }
            const guid = this.bookingReservation && this.bookingReservation.guid;
            if (guid.length > 10) {
                WebBookingAPI.refreshWebBooking(guid, this.system);
            }
            this.setNextStep();
            this.emitChange();
        }
    };

    private handleChangeModelField = (action: Actions.ChangeModelField) => {
        if (!this.hideCoverImage && action.fieldValue !== null) this.hideCoverImage = true;
        let newModel = this.createParametersModel.set(action.fieldName, action.fieldValue);

        if (action.fieldName === "isCompanyCustomer") {
            newModel = newModel.set("wishToBeInvoiced", false);
        }

        this.createParametersModel = newModel;

        // For other changes than companyCustomer, message and invoicing the selected time should be reset
        if (["message", "isCompanyCustomer", "wishToBeInvoiced"].indexOf(action.fieldName) === -1) {
            this.resetSelectedTimes();
            this.filterUnitsResult();
        }

        this.emitChange();
    };

    private handleChangeContactField = (action: Actions.ChangeContactField) => {
        let newContact = new WebBookingContact(this.createParametersModel.contact);
        newContact = newContact.set(action.fieldName, action.fieldValue);
        this.createParametersModel = this.createParametersModel.set("contact", newContact);

        this.emitChange();
    };

    private handleTimeClicked = (action: Actions.TimeClicked) => {
        if (this.advanced) {
            // Keep selections in other rules, and change selection in current rule
            const timeWasAlreadySelected = this.selectedTimes.find((x) => x === action.time);
            this.selectedTimes = this.selectedTimes
                .filter(
                    (x) => x.ruleId !== action.time.ruleId && x.sectionId !== action.time.sectionId
                )
                .toList();
            if (!timeWasAlreadySelected) {
                // Only add new time if not already selected (otherwise, deselection)
                this.selectedTimes = this.selectedTimes.insert(0, action.time);
            }
        } else {
            // Can only select a single time
            this.selectedTimes = List([action.time]);
        }

        this.createParametersModel = this.syncTimesWithModel();

        const time = this.selectedTimes.first();
        const selectedGroupSize = this.createParametersModel.guests;
        const isWaitList = time?.waitListSeats.includes(selectedGroupSize);

        const { activateWebChildren } =
            ExternalBookingSettingsStore.getExternalBookingSettingssResult(this.unitId, this.system)
                .value.unitsMetaData;
        let { guests, guestsChildren } = this.createParametersModel;
        if (!activateWebChildren || guests === 1) {
            guestsChildren = 0;
        }

        reserveWebBooking(
            this.date,
            guests,
            guestsChildren,
            this.selectedTimes,
            this.system,
            this.unitId,
            action.sectionIds,
            action.debug,
            action.hostUrl,
            isWaitList
        );
        this.setNextStep();

        this.emitChange();
    };

    private handleSelectMultipleWaitListSlots = (action: Actions.WaitListTimeClicked) => {
        // Keep selections in other rules, and change selection in current rule
        // const timeWasAlreadySelected = this.selectedTimes.find((x) => x === action.time);
        // if (!timeWasAlreadySelected) {
        //     // If the time wasn't previously selected, add it to the selectedTimes list.
        //     // this.selectedTimes = this.selectedTimes.insert(0, action.time);
        // } else {
        //     // If the time was previously selected, remove it from the selectedTimes list (deselection).
        //     // this.selectedTimes = this.selectedTimes.filter((x) => x !== action.time).toList();
        // }

        this.selectedTimes = action.times;
        this.createParametersModel = this.syncTimesWithModel();

        const { sectionIds, debug, hostURL } = getUrlParams();

        const { activateWebChildren } =
            ExternalBookingSettingsStore.getExternalBookingSettingssResult(this.unitId, this.system)
                .value.unitsMetaData;
        let { guests, guestsChildren } = this.createParametersModel;
        if (!activateWebChildren || guests === 1) {
            guestsChildren = 0;
        }

        reserveWebBooking(
            this.date,
            guests,
            guestsChildren,
            this.selectedTimes,
            this.system,
            this.unitId,
            sectionIds,
            debug,
            hostURL,
            true
        );

        this.setNextStep();

        this.emitChange();
    };

    private syncTimesWithModel(): WebBookingCreateParameters {
        //sync the selectedTimes with model
        const newModel = this.createParametersModel.set(
            "times",
            List<WebBookingCreateParametersRuleTime>(
                this.selectedTimes.map(
                    (t) =>
                        new WebBookingCreateParametersRuleTime({
                            ruleId: t.ruleId,
                            start: t.start,
                            availableUntil: t.availableUntil,
                        })
                )
            )
        );
        return newModel;
    }

    private handleChangeMenuField = (action: Actions.ChangeMenuField) => {
        if (action.value === undefined) {
            this.bookingReservation = <WebBooking>this.bookingReservation.setIn(action.path, 0);
        } else {
            this.bookingReservation = <WebBooking>(
                this.bookingReservation.setIn(action.path, action.value)
            );
        }
        this.nextStepRequested = false;
        //TODO: Do validation of menus here instead of in the view?
        this.emitChange();
    };

    private handleChangePaymentMethod = (action: Actions.ChangePaymentMethod) => {
        this.paymentMethodType = action.Value;
        this.emitChange();
    };

    private handlePostMenuClicked = (_action: Actions.PostMenu) => {
        if (this.bookingReservation.validateMenus().isValid()) {
            //This should already be validated i previous step
            BookingMenuAPI.respond(
                <List<BookingMenuExternal>>(
                    this.bookingReservation.menus.filter(
                        (m) => m.status === BookingMenuStatusEnum.Sent
                    )
                ),
                this.system,
                false
            );
            this.setActiveStepPending();
        }
        this.emitChange();
    };

    private handleCancelBookingClicked = (_action: Actions.CancelBooking) => {
        this.setActiveStepPending();
        this.emitChange();
    };

    private handleGetPaymentTerminalClicked = (_action: Actions.GetPaymentTerminal) => {
        this.setActiveStepPending();
        const parameters = new WebBookingPaymentTerminalParameters({
            terminalReturnUrl: document.location.href,
            contact: this.createParametersModel.contact,
            customer: this.createParametersModel.customer,
            message: this.createParametersModel.message,
            payMethod: this.getSelectedPayMethod(),
        });
        WebBookingStore.getTerminalUrl(this.bookingReservation.guid, parameters, this.system);
        this.emitChange();
    };

    private extractTimesFromUnit(unit: WebBookingUnit): List<IMoment> {
        // @ts-ignore
        return unit.sections
            .map((section) =>
                section.timeSets.map((timeSet) => timeSet.times.map((time) => time.start))
            )
            .flatten()
            .toList();
    }

    private extractTimesFromUnits(webBookingUnits: List<WebBookingUnit>): List<IMoment> {
        // @ts-ignore
        return webBookingUnits
            .map((unit) => this.extractTimesFromUnit(unit))
            .flatten()
            .toList();
    }
    getFromTime() {
        return this.fromTime;
    }

    getFromTimes() {
        return this.fromTimes;
    }

    getWebBookingUnitsResult(): Result<List<WebBookingUnit>> {
        return this.webBookingUnitsResult;
    }

    getViewedMonthDayStates(): Map<string, List<DayState>> {
        return this.viewedMonthDayStates;
    }

    getLoadedMonthDayStateResult(
        m: IMoment,
        unitId: number,
        sectionIds: List<number>,
        system: string
    ): Result<List<DayState>> {
        return WebBookingStore.getLoadedMonthDayStateResult(m, unitId, sectionIds, system);
    }

    getCreateParametersModel() {
        return this.createParametersModel;
    }

    getWebBookingReservation() {
        return this.bookingReservation;
    }

    getPaymentValidationResult() {
        return this.paymentValidationResult;
    }

    getWebBookingId() {
        return this.webBookingId;
    }

    getDate() {
        return this.date;
    }

    getActiveStep() {
        return this.activeStep;
    }

    getApplicableSteps() {
        if (this.activeStep.step === WebBookingStepsEnum.Error) {
            return List<WebBookingStep>();
        }

        const activeStepIndex = this.applicableSteps.findIndex(
            (s) => s.step === this.activeStep.step
        );
        return this.applicableSteps.setIn([activeStepIndex], this.activeStep);
    }

    getNextStep() {
        return this.decideNextStep();
    }

    getPrevStep() {
        return this.decidePrevStep();
    }

    isInitialized() {
        return this.initialized;
    }

    getSelectedTimes() {
        return this.selectedTimes;
    }

    getSelectedPayMethod() {
        return this.paymentMethodType;
    }

    getSelectedSections() {
        return this.selectedSections;
    }

    getConditions(): List<string> {
        let conditions = List<string>();
        if (
            this.externalBookingSettings.webConditions &&
            this.externalBookingSettings.webConditions.length > 0
        )
            conditions = conditions.push(this.externalBookingSettings.webConditions);

        let ruleIds = List<number>();
        this.selectedTimes.forEach((time) => {
            if (!ruleIds.contains(time.ruleId)) {
                // Get the rule by its ID
                const rule = this.getRuleById(time.ruleId);

                // If rule is found and has conditions, add to conditions list
                if (rule?.conditions != null && rule.conditions !== "") {
                    conditions = conditions.push(rule.conditions);
                    // Add the ruleId to the Set to track it as processed
                    ruleIds = ruleIds.push(time.ruleId);
                }
            }
        });
        return conditions;
    }

    getPaymentInformations(): List<string> {
        let paymentInformations = List<string>();
        if (this.selectedTimes && this.selectedTimes.count() > 0) {
            this.selectedTimes.forEach((time) => {
                const rule = this.getRuleById(time.ruleId);
                const paymentInformation = rule?.paymentInformation;
                if (paymentInformation != null && paymentInformation !== "") {
                    paymentInformations = paymentInformations.push(paymentInformation);
                }
            });
        }
        return paymentInformations;
    }

    getSummary(): Summary {
        const summary = <Summary>{};
        summary.guests = this.createParametersModel.guests;
        summary.date = this.date.format(dateFormatString(DateFormat.longWithDash));
        const orderedTimes = this.selectedTimes.sort((a, b) => a.start.unix() - b.start.unix());

        // Find the last rule and subtract recoup time from the end time
        const endRule = this.getRuleById(orderedTimes.last().ruleId);
        const endTime = orderedTimes
            .last()
            .end.clone()
            .subtract(endRule.recoupTime, "minute")
            .format("HH:mm");

        summary.time = `${orderedTimes.first().start.format("HH:mm")} - ${endTime}`;
        summary.times = [];
        orderedTimes.forEach((t) => {
            const rule = this.getRuleById(t.ruleId);
            const time = <any>{};
            time.title = rule.title;
            time.time = `${t.start.format("HH:mm")} - ${t.end.format("HH:mm")}`;
            summary.times.push(time);
        });
        return summary;
    }

    getSystem(): string {
        return this.system;
    }

    getInitialStep(): string {
        return this.initialStep;
    }

    getExternalBookingSettings() {
        return this.externalBookingSettings;
    }

    isActiveStepLoading(): boolean {
        const isLoading = false;
        if (this.activeStep.status === WebBookingStepStatusEnum.ActivePending) {
            return true;
        }
        switch (this.activeStep.step) {
            case WebBookingStepsEnum.Menu:
                return !this.webBookingId
                    ? false
                    : WebBookingStore.getWebBookingResult(this.webBookingId, this.system).status !==
                          this.Loaded;
            default:
                return isLoading;
        }
    }

    activeStepOnceValidated(): boolean {
        return this.activeStep?.validationResult != null;
    }

    getCreatedWebBooking(): WebBooking {
        return this.createdWebBooking;
    }

    isCreatingWebBooking(): boolean {
        return this.creatingWebBooking;
    }

    getUnitTimezone(): string {
        if (!this.tz) {
            const unitsResult = this.getWebBookingUnitsResult();
            // @ts-ignore
            const units: AvailableTimesUnit<IMoment>[] = unitsResult.value.toJS();

            // Set current time based a unit timeZone (they are all given root tz from api)
            this.tz = units.pop()?.timeZone ?? "Europe/Stockholm";
        }
        return this.tz;
    }

    getTzOffset(): number {
        if (!this.offset) {
            // Take timezone from unit
            const unitTz = this.getUnitTimezone();
            // Get difference with system standard (Swedish) time: +1 or +2 depending on daylight saving
            this.offset = offsetBetweenTimeZones(unitTz);
        }
        return this.offset;
    }

    getNow(): IMoment {
        return moment().add(this.getTzOffset(), "minutes");
    }

    filterUnitsResult() {
        const unitsResult = this.getWebBookingUnitsResult();
        const units: AvailableTimesUnit<IMoment>[] = unitsResult.value.toJS();

        for (const unit of units) {
            // Set current time based on unit timeZone
            const now = this.getNow();

            unit.sections = unit.sections.filter((s) => {
                return (
                    ((this.selectedSections &&
                        this.selectedSections.isEmpty() &&
                        !this.onlineorder) ||
                        this.selectedSections.some((ss) => ss.id === s.id)) &&
                    s.timeSets.length > 0
                );
            });

            for (const section of unit.sections) {
                section.timeSets = section.timeSets.filter((timeSet) => {
                    if (!timeSet.maxGroupSize || !timeSet.maxGroupSize) return true;
                    return (
                        this.createParametersModel.guests <= timeSet.maxGroupSize &&
                        this.createParametersModel.guests >= timeSet.minGroupSize
                    );
                });

                for (const timeSet of section.timeSets) {
                    timeSet.times = timeSet.times.filter((time) =>
                        time.start.isSameOrAfter(this.fromTime)
                    );

                    for (const time of timeSet.times) {
                        if (now.isAfter(time.availableUntil)) {
                            time.disabled = true;

                            // If full unavailability (with no group size limit) already exists from server, prefer that. Or else, set lock time unavailability info
                            if (
                                time.unavailability &&
                                (time.unavailability.length === 0 ||
                                    time.unavailability[0].from != null ||
                                    time.unavailability[0].to !== null)
                            ) {
                                time.unavailability = [
                                    new WebBookingUnavailability({
                                        reason: WebBookingUnavailabilityReason.LockTimeHasPassed,
                                    }),
                                ];
                            }
                        } else if (
                            this.createParametersModel.guests === 0 ||
                            (time.availableSeats.indexOf(this.createParametersModel.guests) < 0 &&
                                !time.waitListSeats.includes(this.createParametersModel.guests)) ||
                            timeSet.isClosed
                        ) {
                            time.disabled = true;
                        }
                        if (time.waitListSeats.includes(this.createParametersModel.guests)) {
                            time.isWaitList = true;
                        }
                    }

                    if (this.onlineorder) {
                        timeSet.times = timeSet.times.filter((time) => !time.disabled);
                    }
                }

                const groupedTimeSets = List(section.timeSets)
                    .groupBy((timeSet) => timeSet.title)
                    .toJS();
                let updatedTimeSets: AvailableTimesTimeSet<IMoment>[] = [];
                const groupedTimeSetKeys = Object.keys(groupedTimeSets);

                groupedTimeSetKeys.forEach((key) => {
                    const timeSet = groupedTimeSets[key];
                    timeSet.forEach((ts) => {
                        ts.times = List(ts.times as any)
                            .sortBy((time: any) => time.start)
                            .toJS();
                        // @ts-ignore
                        updatedTimeSets = [...updatedTimeSets, ts];
                    });
                });

                section.timeSets = updatedTimeSets;
            }
        }

        this.filteredUnitsResult = new Result({
            status: unitsResult.status,
            value: List(units.map((unit: any) => new WebBookingUnit(unit))),
        });

        if (this.onlineorder) {
            this.createParametersModel = this.createParametersModel.set(
                "times",
                List<WebBookingCreateParametersRuleTime>([])
            );
        }
    }

    getFilteredUnitsResult(): Result<List<WebBookingUnit>> {
        return this.filteredUnitsResult;
    }

    getUnitWithAvailableTimes(): WebBookingUnit | null {
        const resultValue = this.getFilteredUnitsResult()?.value as List<WebBookingUnit>;
        const firstUnit = resultValue?.first();
        return firstUnit;
    }

    private setActiveStepPendingAndEmit = () => {
        this.activeStep = <WebBookingStep>(
            this.activeStep.set("status", WebBookingStepStatusEnum.ActivePending)
        );
        this.emitChange();
    };

    private setActiveStepPending() {
        this.activeStep = <WebBookingStep>(
            this.activeStep.set("status", WebBookingStepStatusEnum.ActivePending)
        );
    }

    private setActiveStepActive() {
        this.activeStep = <WebBookingStep>(
            this.activeStep.set("status", WebBookingStepStatusEnum.Active)
        );
    }

    private getRuleById(ruleId: number): WebBookingTimeRule {
        const webBookingUnits = this.getWebBookingUnitsResult().value;
        return webBookingUnits
            .flatMap((u) => u.sections)
            .flatMap((s) => s.timeSets)
            .find((t) => t.id === ruleId);
    }

    private resetActiveStepValidation = () => {
        this.activeStep = <WebBookingStep>(
            this.activeStep.set("validationResult", new ValidationResult())
        );
    };

    private validateActiveStep() {
        let validationResult = new ValidationResult();
        switch (this.activeStep.step) {
            case WebBookingStepsEnum.Start:
                validationResult = this.createParametersModel.validate();
                break;
            case WebBookingStepsEnum.ContactInformation:
                let mandatoryFields: MandatoryWebBookingContactFields =
                    new MandatoryWebBookingContactFields();
                const selectedTime = this.getSelectedTimes().first();

                let rule: WebBookingTimeRule | null = null;

                if (selectedTime) {
                    rule = this.getRuleById(selectedTime.ruleId);
                }

                if (rule && rule.bookingType === WebTimeRuleBookingTypeEnum.Delivery) {
                    mandatoryFields = mandatoryFields
                        .set("streetaddress", true)
                        .set("zipcode", true)
                        .set("city", true);

                    if (rule.forceDeliveryInfo) {
                        mandatoryFields = mandatoryFields.set("deliveryInfo", true);
                    }
                }

                if (!this.customerLoadedByFriendlyId) {
                    if (this.createParametersModel.isCompanyCustomer) {
                        mandatoryFields = mandatoryFields.set("customerName", true);
                    }

                    if (this.createParametersModel.wishToBeInvoiced) {
                        mandatoryFields = mandatoryFields
                            .set("organizationCode", true)
                            .set("invoiceStreetAddress", true)
                            .set("invoiceEmail", true);
                    }
                }

                this.createParametersModel = this.createParametersModel.setIn(
                    ["contact", "customerName"],
                    this.createParametersModel.contact.customerName.trim()
                );
                this.createParametersModel = this.createParametersModel.setIn(
                    ["contact", "fname"],
                    this.createParametersModel.contact.fname.trim()
                );
                this.createParametersModel = this.createParametersModel.setIn(
                    ["contact", "lname"],
                    this.createParametersModel.contact.lname.trim()
                );

                const contact = new WebBookingContact(this.createParametersModel.contact);
                validationResult = contact.validate(mandatoryFields);
                break;
            case WebBookingStepsEnum.Menu:
                validationResult = this.bookingReservation.validateMenus();
                break;
            default:
                break;
        }
        this.activeStep = <WebBookingStep>this.activeStep.set("validationResult", validationResult);
        //this.validationResult = validationResult;
    }

    private setNextStep() {
        this.nextStepRequested = false;
        const activeStepIndex = this.applicableSteps.findIndex(
            (s) => s.step === this.activeStep.step
        );
        this.applicableSteps = this.applicableSteps.setIn(
            [activeStepIndex, "status"],
            WebBookingStepStatusEnum.Completed
        );
        this.activeStep = this.decideNextStep();
        let stepStatus = WebBookingStepStatusEnum.Active;
        if (this.bookingReservation && this.bookingReservation.isInUpdate)
            stepStatus = WebBookingStepStatusEnum.ActivePending;
        this.activeStep = <WebBookingStep>this.activeStep.set("status", stepStatus);
    }

    private decideNextStep(): WebBookingStep {
        const activeStepIndex = this.applicableSteps.findIndex(
            (s) => s.step === this.activeStep.step
        );

        for (let i = activeStepIndex + 1; i < this.applicableSteps.count(); i++) {
            const stepRecord = this.applicableSteps.get(i);
            if (this.isStepNeeded(stepRecord.step)) {
                return stepRecord;
            }
        }
        return new WebBookingStep({ step: WebBookingStepsEnum.Error });
    }

    private setPrevStep() {
        const activeStepIndex = this.applicableSteps.findIndex(
            (s) => s.step === this.activeStep.step
        );
        this.applicableSteps = this.applicableSteps.setIn(
            [activeStepIndex, "status"],
            WebBookingStepStatusEnum.Incomplete
        );
        this.activeStep = this.decidePrevStep();
        this.activeStep = <WebBookingStep>(
            this.activeStep.set("status", WebBookingStepStatusEnum.Active)
        );
    }

    private decidePrevStep() {
        const activeStepIndex = this.applicableSteps.findIndex(
            (s) => s.step === this.activeStep.step
        );
        for (let i = activeStepIndex - 1; i >= 0; i--) {
            const stepRecord = this.applicableSteps.get(i);
            if (
                stepRecord.status === WebBookingStepStatusEnum.Completed &&
                this.isStepChangeable(stepRecord.step)
            ) {
                return stepRecord;
            }
            //  else {
            //   return null; //TODO: if some step in applicableSteps was not needed, it will not be posible to go to the previous step
            // }
        }
        return null;
    }

    private setApplicableSteps() {
        this.applicableSteps = List<WebBookingStep>([
            new WebBookingStep({ step: WebBookingStepsEnum.Start }),
            new WebBookingStep({ step: WebBookingStepsEnum.ContactInformation }),
            new WebBookingStep({ step: WebBookingStepsEnum.Final }),
        ]);
        return;

        if (this.bookingReservation) {
            if (this.webBookingId == null && !this.bookingReservation.start.isValid()) {
                //new web booking
                this.applicableSteps = List<WebBookingStep>([
                    new WebBookingStep({ step: WebBookingStepsEnum.Start }),
                    new WebBookingStep({ step: WebBookingStepsEnum.Menu }),
                    new WebBookingStep({ step: WebBookingStepsEnum.MenuSummary }),
                    new WebBookingStep({ step: WebBookingStepsEnum.ContactInformation }),
                    new WebBookingStep({ step: WebBookingStepsEnum.BookingSummary }),
                    new WebBookingStep({ step: WebBookingStepsEnum.PaymentTerminal }),
                    new WebBookingStep({ step: WebBookingStepsEnum.Final }),
                ]);
                return;
            }
            if (this.bookingReservation.status === BookingStatus.Canceled) {
                this.applicableSteps = List<WebBookingStep>([
                    new WebBookingStep({ step: WebBookingStepsEnum.BookingCanceled }),
                ]);
            } else if (this.chargeId !== null) {
                if (
                    this.bookingReservation &&
                    (this.bookingReservation.chargePayedAmount > 0 ||
                        (this.bookingReservation.chargeType === ChargeTypeEnum.NoShow &&
                            this.bookingReservation.chargeStatus !==
                                ChargeStatusEnum.PaymentFailed))
                ) {
                    this.applicableSteps = List<WebBookingStep>([
                        //If charge is payed or type is NoShow without status PaymentFailed, booking is finalized
                        new WebBookingStep({ step: WebBookingStepsEnum.BookingSummary }),
                    ]);
                } else {
                    this.applicableSteps = List<WebBookingStep>([
                        new WebBookingStep({ step: WebBookingStepsEnum.BookingSummary }),
                        new WebBookingStep({ step: WebBookingStepsEnum.PaymentTerminal }),
                        new WebBookingStep({ step: WebBookingStepsEnum.Final }),
                    ]);
                }
            } else if (this.paymentLink) {
                //Payment link
                this.applicableSteps = List<WebBookingStep>([
                    new WebBookingStep({ step: WebBookingStepsEnum.BookingSummary }),
                    new WebBookingStep({ step: WebBookingStepsEnum.PaymentTerminal }),
                    new WebBookingStep({ step: WebBookingStepsEnum.Final }),
                ]);
            } else if (this.initialStep === "cancel") {
                if (
                    !this.bookingReservation.cancelAllowedBeforeUtc ||
                    this.bookingReservation.cancelAllowedBeforeUtc.isBefore(moment().utc())
                ) {
                    this.applicableSteps = List<WebBookingStep>([
                        new WebBookingStep({ step: WebBookingStepsEnum.BookingCancel }),
                    ]);
                } else {
                    this.applicableSteps = List<WebBookingStep>([
                        new WebBookingStep({ step: WebBookingStepsEnum.BookingCancel }),
                        new WebBookingStep({ step: WebBookingStepsEnum.BookingCancelSuccess }),
                    ]);
                }
            } else if (this.bookingReservation.menus.isEmpty()) {
                this.applicableSteps = List<WebBookingStep>([
                    new WebBookingStep({ step: WebBookingStepsEnum.NoMenu }),
                ]);
            } else if (
                this.bookingReservation.menus
                    .filter((m) => m.status === BookingMenuStatusEnum.Sent)
                    .isEmpty()
            ) {
                this.applicableSteps = List<WebBookingStep>([
                    new WebBookingStep({ step: WebBookingStepsEnum.MenuAlreadyConfirmed }),
                ]);
            } else if (
                this.bookingReservation.menus.every((m) =>
                    m.groups.every((g) => g.groupType === BookingMenuGroupType.ViewOnly)
                )
            ) {
                this.applicableSteps = List<WebBookingStep>([
                    new WebBookingStep({ step: WebBookingStepsEnum.MenuAlreadyConfirmed }),
                ]);
            } else {
                this.applicableSteps = List<WebBookingStep>([
                    new WebBookingStep({ step: WebBookingStepsEnum.Menu }),
                    new WebBookingStep({ step: WebBookingStepsEnum.MenuSummary }),
                    new WebBookingStep({ step: WebBookingStepsEnum.MenuFinal }),
                ]);
            }
            return;
        }
        this.applicableSteps = List<WebBookingStep>();
    }

    private isStepSkippable(step: WebBookingStepsEnum): boolean {
        let allowSkip = false;
        switch (step) {
            case WebBookingStepsEnum.Menu:
                allowSkip = true;
                for (let timeIndex = 0; timeIndex < this.getSelectedTimes().count(); timeIndex++) {
                    const time = this.getSelectedTimes().get(timeIndex);
                    if (time.requireMenuSelection === true) {
                        // if any of the time items have requiredMenuSelection = true, the menu-step cannot be skipped
                        allowSkip = false;
                    }
                }
                break;
            default:
                break;
        }
        return allowSkip;
    }

    private isStepNeeded(step: WebBookingStepsEnum): boolean {
        //TODO some logic to see if this potentially next step is applicable
        //for example, step is not needed if none of the selected times needs to be payed

        //Always return true if step is completed, messes up the StepsOverview otherwise
        const stepRecord = this.applicableSteps.find((s) => s.step === step);
        if (stepRecord && stepRecord.status === WebBookingStepStatusEnum.Completed) return true;

        switch (step) {
            case WebBookingStepsEnum.MenuSummary:
                let showMenySummary = false;
                if (
                    this.bookingReservation &&
                    this.bookingReservation.menus !== null &&
                    this.bookingReservation.status !== BookingStatusEnum.WaitList
                ) {
                    this.bookingReservation.menus.forEach((menu) => {
                        if (menu.isAnyQuantitySelectionMade()) {
                            showMenySummary = true;
                        }
                    });
                }
                return showMenySummary;
            case WebBookingStepsEnum.Menu:
            case WebBookingStepsEnum.MenuFinal:
                return (
                    this.bookingReservation.status !== BookingStatusEnum.WaitList &&
                    ((this.bookingReservation &&
                        this.bookingReservation.menus &&
                        this.bookingReservation.status !== BookingStatus.Canceled &&
                        !this.bookingReservation.menus
                            .filter(
                                (m) =>
                                    m.status === BookingMenuStatusEnum.Sent ||
                                    m.status === BookingMenuStatusEnum.Confirmed
                            )
                            .isEmpty()) ||
                        this.selectedTimes.some((t) => t.hasMenu))
                );
            case WebBookingStepsEnum.MenuAlreadyConfirmed:
                return (
                    this.bookingReservation &&
                    this.bookingReservation.menus &&
                    (this.bookingReservation.status === BookingStatus.Canceled ||
                        this.bookingReservation.menus
                            .filter((m) => m.status === BookingMenuStatusEnum.Sent)
                            .isEmpty())
                );
            case WebBookingStepsEnum.BookingCanceled:
                return (
                    this.bookingReservation &&
                    this.bookingReservation.status === BookingStatus.Canceled
                );
            case WebBookingStepsEnum.PaymentTerminal:
                return (
                    this.bookingReservation &&
                    this.bookingReservation.chargeType !== ChargeTypeEnum.None
                );
            case WebBookingStepsEnum.BookingCancel:
                return (
                    this.bookingReservation &&
                    this.bookingReservation.status !== BookingStatus.Canceled
                );
            case WebBookingStepsEnum.Start:
            case WebBookingStepsEnum.Final:
            case WebBookingStepsEnum.NoMenu:
            case WebBookingStepsEnum.LinkError:
            case WebBookingStepsEnum.PostError:
            case WebBookingStepsEnum.ContactInformation:
            case WebBookingStepsEnum.BookingSummary:
            case WebBookingStepsEnum.Condition:
            case WebBookingStepsEnum.BookingCancelSuccess:
                // return (!this.getConditions().isEmpty());
                return true;
            default:
                return false;
        }
    }

    private isStepChangeable(step: WebBookingStepsEnum): boolean {
        switch (step) {
            case WebBookingStepsEnum.Menu:
                return (
                    this.bookingReservation &&
                    this.bookingReservation.menus &&
                    this.bookingReservation.status !== BookingStatus.Canceled &&
                    !this.bookingReservation.menus
                        .filter((m) => m.status === BookingMenuStatusEnum.Sent)
                        .isEmpty()
                );
            case WebBookingStepsEnum.Start:
            case WebBookingStepsEnum.ContactInformation:
            case WebBookingStepsEnum.Condition:
            case WebBookingStepsEnum.BookingSummary:
                return this.createdWebBooking === null;
            default:
                return false;
        }
    }

    getNextStepRequested = (): boolean => {
        return this.nextStepRequested;
    };

    getCurrency(): string {
        // TODO: This should read currency from CompanyInformation
        if (this.system) {
            const prefix = this.system.split("_")[0];
            switch (prefix) {
                case "no":
                    return "NOK";
                case "us":
                    return "USD";
                case "se":
                    return "SEK";
                case "dk":
                    return "DKK";
                case "fi":
                    return "EUR";
                case "be":
                    return "EUR";
                case "ee":
                    return "EUR";
                case "ru":
                    return "RUB";
                case "nl":
                    return "EUR";
                case "es":
                    return "EUR";
                default:
                    return "SEK";
            }
        }
        return "SEK";
    }

    getCustomerLoadedByFriendlyId = (): WebBookingCustomer | null => {
        return this.customerLoadedByFriendlyId;
    };

    getContactLoadedByFriendlyId = (): WebBookingContact | null => {
        return this.contactLoadedByFriendlyId;
    };

    getHideCoverImage = (): boolean => {
        return this.hideCoverImage;
    };

    getState(): IWebBookingViewStoreState {
        return {
            createParametersModel: this.getCreateParametersModel(),
            bookingReservation: this.getWebBookingReservation(),
            selectedTimes: this.getSelectedTimes(),
            activeStepOnceValidated: this.activeStepOnceValidated(),
            activeStep: this.activeStep,
            applicableSteps: this.applicableSteps,
            activeStepLoading: this.isActiveStepLoading(),
            nextStep: this.getNextStep(),
            prevStep: this.getPrevStep(),
            nextStepRequested: this.getNextStepRequested(),
            webBookingConditions: this.getConditions(),
            hideCoverImage: this.getHideCoverImage(),
            selectedSections: this.getSelectedSections(),
            paymentValidationResult: this.getPaymentValidationResult(),
            filteredUnitsResult: this.getFilteredUnitsResult(),
            selectedPaymentMethod: this.getSelectedPayMethod(),
            webBookingUnitsResult: this.getWebBookingUnitsResult(),
            paymentInformations: this.getPaymentInformations(),
            viewedMonthDayStates: this.getViewedMonthDayStates(),
            fromTimes: this.getFromTimes(),
            currency: this.getCurrency(),
            firstEventTime: this.firstEventTime,
            date: this.date,
            unitWithAvailableTimes: this.getUnitWithAvailableTimes(),
            contactLoadedByFriendlyId: this.getContactLoadedByFriendlyId(),
        };
    }
}

const webBookingViewStore = new WebBookingViewStore();
export default webBookingViewStore;
