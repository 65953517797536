import { Grid, GridItem } from "@caspeco/casper-ui-library.components.grid";
import { useDisclosure } from "@chakra-ui/react";
import {
    Box,
    Flex,
    Heading,
    Text,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeFontVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import WaitlistTime from "components/wait-list/wait-list-time-slot";
import { useBookingViewStore } from "hooks";
import { List } from "immutable";
import { WaitListTimeClicked } from "old/actions/webBookingViewActions";
import { SafeHTML } from "components/safe-HTML";
import { WebBookingTimeRule } from "old/models/webBookingTimeRule";
import { WebBookingTimeRuleUnit } from "old/models/webBookingTimeRuleUnit";
import webBookingStore from "old/stores/webBookingStore";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getUrlParams, setSearch } from "utils";
import WebBookingTime from "./time-slot";
import WaitlistModal from "components/wait-list/wait-list-modal";
import { getSortedHourKeys, groupedTimes } from "../../../utils/booking";

interface ITimeSetProps {
    timeSet: WebBookingTimeRule;
    onTimeClick: (time: WebBookingTimeRuleUnit, timeSet: WebBookingTimeRule) => void;
}

function TimeSet(props: ITimeSetProps) {
    const { activeStepLoading, createParametersModel, selectedTimes } = useBookingViewStore();
    const [webBookingUnits, setWebBookingUnits] = useState(
        webBookingStore.getState().webBookingUnits
    );
    const { advanced } = getUrlParams();

    const navigate = useNavigate();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { t } = useTranslation();

    const [selectedWaitListTimes, setSelectedWaitListTimes] =
        useState<List<WebBookingTimeRuleUnit>>(List());

    // subscribe to store changes
    useEffect(() => {
        const onStoreChange = () => {
            setWebBookingUnits(webBookingStore.getState().webBookingUnits);
        };
        webBookingStore.onChange(onStoreChange);
        return () => {
            webBookingStore.offChange(onStoreChange);
        };
    }, []);

    const timeSet = props.timeSet;

    if (timeSet.times.count() < 1 && timeSet.isTextRow === false) return null;

    const timeHeading: JSX.Element[] = [];

    if (timeSet.isTextRow && !timeSet.isClosed) {
        if (timeSet.title) {
            timeHeading.push(
                <Text
                    key={`timeSetTitle_${timeSet.id}`}
                    color={ThemeColorVariable.OnSurfaceHeader}
                    fontSize={ThemeFontSizeVariable.Large}
                    fontWeight="500"
                    mb={ThemeSpaceVariable.Small}
                >
                    <SafeHTML
                        className="textFromSettings"
                        element="span"
                        options={{
                            allowedTags: ["a", "br"],
                            allowedSchemes: ["tel", "mailto", "https"],
                        }}
                        html={timeSet.title}
                    />
                </Text>
            );
        }

        if (timeSet.comment) {
            timeHeading.push(
                <Text
                    key={`timeSetTitle_comment_${timeSet.id}`}
                    fontFamily={ThemeFontVariable.SubHeader}
                    fontSize={ThemeFontSizeVariable.Medium}
                    fontWeight="500"
                    mb={ThemeSpaceVariable.Small}
                >
                    <SafeHTML
                        className="textFromSettings"
                        element="span"
                        options={{
                            allowedTags: ["a", "br"],
                            allowedSchemes: ["tel", "mailto", "https"],
                        }}
                        html={timeSet.comment}
                    />
                </Text>
            );
        }

        return <>{timeHeading}</>;
    }

    const handleWaitlistClick = (time: WebBookingTimeRuleUnit) => {
        if (!isOpen) onOpen();

        const timeIndex = selectedWaitListTimes.indexOf(time);

        let newSelectedTimes = selectedWaitListTimes;

        if (timeIndex > -1) {
            // If the time exists, remove it from the array
            newSelectedTimes = selectedWaitListTimes.splice(timeIndex, 1);
            if (selectedWaitListTimes.splice(timeIndex, 1).count() === 0) onClose();
        } else {
            newSelectedTimes = selectedWaitListTimes.push(time);
        }

        setSelectedWaitListTimes(newSelectedTimes);

        let ruleId = "";
        let timeString = "";

        if (newSelectedTimes.count() === 0) {
            onClose();
        } else {
            ruleId = "" + newSelectedTimes.get(0).ruleId;
            timeString = newSelectedTimes
                .map((t) => (t.toJS() as WebBookingTimeRuleUnit).start.format("HHmm"))
                .toArray()
                .join("-");
        }

        setSearch(navigate, "r", ruleId);
        setSearch(navigate, "time", timeString);
    };

    const handleWaitlistModalClose = () => {
        onClose();
        setSelectedWaitListTimes(List());
    };

    const handleWaitlistContinue = () => {
        new WaitListTimeClicked(selectedWaitListTimes);
    };

    function getTimeSlotList(
        times: List<WebBookingTimeRuleUnit>,
        isWaitListSet = false,
        selectWaitListSlots = false
    ) {
        const itemList: React.ReactNode[] = [];

        let slotsFilledInCurrentRow = 0;
        let itemCount = 1;

        const magicNumber = 4;

        const groupedTimesByHour = groupedTimes(times);
        const hourKeys = getSortedHourKeys(Object.keys(groupedTimesByHour));

        hourKeys?.forEach((hour, index) => {
            const timesThisHour = groupedTimesByHour[hour];
            const nextHourTimes = groupedTimesByHour[hourKeys[index + 1]] || [];

            timesThisHour?.forEach((time: WebBookingTimeRuleUnit, timeIndex: number) => {
                const row = Math.ceil(itemCount / magicNumber);
                const column = itemCount % magicNumber;

                itemList.push(
                    <GridItem
                        key={`time-${time.ruleId}-${time.start.format("HHmm")}`}
                        gridColumn={column}
                        gridRow={row}
                    >
                        {isWaitListSet ? (
                            <WaitlistTime
                                onTimeClick={handleWaitlistClick}
                                selectedTimes={selectWaitListSlots ? selectedWaitListTimes : List()}
                                time={time}
                            />
                        ) : (
                            <WebBookingTime
                                key={`webBookingTime-${time.ruleId}-${time.start.format("HHmm")}`}
                                time={time}
                                timeSet={timeSet}
                                onTimeClick={props.onTimeClick}
                                selectedTimes={selectedTimes}
                                activeStepLoading={activeStepLoading}
                                createParametersModel={createParametersModel}
                                advanced={advanced}
                                webBookingUnits={webBookingUnits}
                                isWaitListSlot={isWaitListSet}
                            />
                        )}
                    </GridItem>
                );

                slotsFilledInCurrentRow = itemCount % magicNumber;
                const isLastTimeInHour = timeIndex === timesThisHour.length - 1;
                if (isLastTimeInHour) {
                    const remainingSlotsInCurrentRow = magicNumber - slotsFilledInCurrentRow;

                    if (!(nextHourTimes.length <= remainingSlotsInCurrentRow)) {
                        itemCount += remainingSlotsInCurrentRow;
                    }
                }
                itemCount++;
            });
        });

        return itemList;
    }

    timeHeading.push(
        <Heading
            as="h6"
            key={`timeSetTitle_two${timeSet.id}`}
            color={ThemeColorVariable.OnSurfaceHeader}
            fontSize={ThemeFontSizeVariable.Large}
            fontWeight="500"
            mb={ThemeSpaceVariable.Small}
        >
            <SafeHTML
                className="textFromSettings"
                element="span"
                options={{
                    allowedTags: ["a", "br"],
                    allowedSchemes: ["tel", "mailto", "https"],
                }}
                html={timeSet.title}
            />
        </Heading>
    );

    let loneComment = null;
    if (timeSet.comment) {
        loneComment = (
            <Box
                //TODO: Kolla men Louice kring att lägga till beskrivning utöver kommentar
                backgroundColor={ThemeColorVariable.NeutralContainer}
                color={ThemeColorVariable.OnNeutralContainer}
                paddingTop={ThemeSpaceVariable.XSmall}
                paddingLeft={ThemeSpaceVariable.Medium}
                paddingRight={ThemeSpaceVariable.Medium}
                paddingBottom={ThemeSpaceVariable.XSmall}
                borderRadius={ThemeSpaceVariable.XSmall}
                marginBottom={ThemeSpaceVariable.Medium}
            >
                <Text
                    key={`timeSetTitle_comment_two${timeSet.id}`}
                    fontFamily={ThemeFontVariable.Body}
                    fontSize={ThemeFontSizeVariable.Medium}
                >
                    <SafeHTML
                        className="textFromSettings"
                        element="span"
                        options={{
                            allowedTags: ["a", "br"],
                            allowedSchemes: ["tel", "mailto", "https"],
                        }}
                        html={timeSet.comment.replace(/\n/g, "<br>")} // Convert \n to <br> for display
                    />
                </Text>
            </Box>
        );
    }

    const waitListTimes = timeSet.times.filter((time) => {
        const selectedGroupSize = createParametersModel.guests;
        return time.waitListSeats.includes(selectedGroupSize);
    });

    const bookableTimes = timeSet.times.filter((time) => {
        const selectedGroupSize = createParametersModel.guests;
        return time.availableSeats.includes(selectedGroupSize);
    });

    const regularSet = getTimeSlotList(bookableTimes);
    const waitListSet = getTimeSlotList(waitListTimes, true);

    const noAvailableSlots = regularSet.length === 0 && waitListSet.length === 0;

    if (noAvailableSlots) return null;

    return (
        <>
            <Box mb={ThemeSpaceVariable.Large}>
                {timeHeading}
                {regularSet.length > 0 && (
                    <Flex direction="column">
                        {loneComment}
                        <Grid templateColumns="repeat(4, 1fr)" gap={ThemeSpaceVariable.Small}>
                            {regularSet}
                        </Grid>
                    </Flex>
                )}
                {waitListSet.length > 0 && (
                    <Box mt={regularSet.length > 0 ? ThemeSpaceVariable.Medium : "0px"}>
                        <Text
                            key={`timeSetTitle_${timeSet.id}`}
                            fontWeight="500"
                            color={ThemeColorVariable.OnSurfaceHeader}
                            fontSize={ThemeFontSizeVariable.Medium}
                            mb={ThemeSpaceVariable.Small}
                        >
                            {t("waitList.waitList")}
                        </Text>
                        <Grid templateColumns="repeat(4, 1fr)" gap={ThemeSpaceVariable.Small}>
                            {waitListSet}
                        </Grid>
                    </Box>
                )}
            </Box>
            <WaitlistModal
                isOpen={isOpen}
                handleWaitlistContinue={handleWaitlistContinue}
                handleWaitlistModalClose={handleWaitlistModalClose}
                waitListTimes={waitListTimes}
                selectedTimes={selectedWaitListTimes}
                onTimeClick={handleWaitlistClick}
            />
        </>
    );
}

export default React.memo(TimeSet);
