import {
    ReactNode,
    createContext,
    useContext,
    useState,
    SetStateAction,
    Dispatch,
    FC,
} from "react";

interface ErrorContextType {
    error: unknown;
    setError: Dispatch<SetStateAction<unknown>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
}

interface ErrorProviderProps {
    children: ReactNode;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const ErrorProvider: FC<ErrorProviderProps> = ({ children }) => {
    const [error, setError] = useState<unknown>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <ErrorContext.Provider value={{ error, setError, isLoading, setIsLoading }}>
            {children}
        </ErrorContext.Provider>
    );
};

export const useError = () => {
    const context = useContext(ErrorContext);
    if (!context) {
        throw new Error("useError must be used within an ErrorProvider");
    }
    return context;
};
