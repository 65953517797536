import { InputGroup, Spinner } from "@chakra-ui/react";
import { Button, FormControl, Grid, ThemeSpaceVariable, Icons } from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";
import SelectionHeader from "./selection-header";
import { ChangeModelField } from "old/actions/webBookingViewActions";
import { useBookingViewStore } from "hooks";
import { useNavigate } from "react-router-dom";
import { getUrlParams, setSearch } from "utils";
import { trackEvent } from "logging/insights";
import React, { useEffect } from "react";

export const SelectChildrenAmount = () => {
    const [loading, setLoading] = React.useState(true);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = getUrlParams();

    const { guests } = useBookingViewStore().createParametersModel;
    const handleGuestsChildrenChange = (value: string) => {
        const childrenCount = parseInt(value);
        new ChangeModelField("guestsChildren", childrenCount);

        trackEvent("SelectChildrenAmount", {
            guests: guests,
            children: childrenCount,
        });

        if (params.guestsChildren !== childrenCount) {
            setSearch(navigate, "children", value);
        }
    };

    useEffect(() => {
        if (params.guestsChildren !== null) {
            handleGuestsChildrenChange("" + params.guestsChildren);
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) return <Spinner />;

    return (
        <FormControl key="guest-children" id="guestsChildren">
            <SelectionHeader
                id="guestChildren"
                icon={Icons.Child}
                text={t("start.ofWhichChildren")}
            />
            <InputGroup>
                <Grid
                    templateColumns={{
                        base: "repeat(4, 1fr)",
                        sm: "repeat(4, 1fr)",
                        md: "repeat(5, 1fr)",
                    }}
                    gap={ThemeSpaceVariable.Small}
                    width="100%"
                >
                    {[...Array(guests).keys()].map((children) => {
                        return (
                            <Button
                                key={children}
                                h="56px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                w="100%"
                                gridColumn={!children ? "span 2" : ""}
                                variant="card"
                                onClick={() => handleGuestsChildrenChange(children.toString())}
                            >
                                {!children ? t("start.ofWhichNone") : children}
                            </Button>
                        );
                    })}
                </Grid>
            </InputGroup>
        </FormControl>
    );
};
