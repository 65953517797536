import { List } from "immutable";
import { WebBookingTimeRule } from "old/models/webBookingTimeRule";

/**
 * Merges objects in an array based on a specified key.
 *
 * @param {Array} timeSets - The array of objects to merge.
 * @return {List<WebBookingTimeRule>} - An Immutable List of merged objects.
 */
export function mergeTimeSetsByTitle(timeSets: List<WebBookingTimeRule>): List<WebBookingTimeRule> {
    const timeSetMap: { [key: string]: WebBookingTimeRule } = {};
    const mergeKey = "title";

    timeSets.forEach((timeSet) => {
        const key = timeSet[mergeKey];
        const existingTimeSet = timeSetMap[key];
        if (existingTimeSet) {
            // Group by the 'start' property to ensure unique times, then flatten the groups
            const groupedTimes = existingTimeSet.times
                .concat(timeSet.times)
                .groupBy((time) => time.start)
                .map((group) => group.first())
                .toList();
            timeSetMap[key] = existingTimeSet.set("times", groupedTimes);
        } else {
            // Add the current timeSet directly to the map if it's not already there
            timeSetMap[key] = timeSet;
        }
    });

    // Convert the values of the map to an Immutable List
    return List(Object.values(timeSetMap));
}
