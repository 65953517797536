import { Box, Heading, Icon, Icons, Stack, ThemeFontSizeVariable, Text } from "caspeco-casper-ui";
import { useTranslation } from "react-i18next";
import { PhoneNumberLink } from "components/links/phone-number-link";

export function BookingSuccess() {
    const { t } = useTranslation();
    return (
        <Stack alignItems="center">
            <Box backgroundColor="#25AF72" padding="2px" borderRadius="50%" marginBottom="16px">
                <Icon icon={Icons.Success} fontSize="60px" color="white" />
            </Box>
            <Heading as="h1" maxW="340" fontWeight="500" textAlign="center">
                {t("thanks")}
            </Heading>

            <Text maxW="496" textAlign="center" fontSize={ThemeFontSizeVariable.Large}>
                {t("bookingSuccessMessage")}
            </Text>
            <PhoneNumberLink />
        </Stack>
    );
}
