import {
    Button,
    Divider,
    Flex,
    Icon,
    Icons,
    Text,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { DayState } from "old/models/dayState";
import { useTranslation } from "react-i18next";

interface IFirstAvailableDayProps {
    dayState: DayState;
    goToNextAvailableDay: () => void;
}

const FirstAvailableDay = ({ dayState, goToNextAvailableDay }: IFirstAvailableDayProps) => {
    const { t } = useTranslation();
    const date = dayState?.day.format("DD MMMM");
    return (
        <>
            <Divider my={ThemeSpaceVariable.Large} />
            <Flex align="flex-end" direction="column" gap={ThemeSpaceVariable.Small}>
                <Text fontSize={ThemeFontSizeVariable.Small} fontWeight="medium">
                    {t("firstAvailableDay", { date })}
                </Text>
                <Button
                    variant="link"
                    gap={ThemeSpaceVariable.XSmall}
                    onClick={goToNextAvailableDay}
                >
                    {t("goToFirstAvailableDay")}
                    <Icon icon={Icons.Forward} fontSize="20px" />
                </Button>
            </Flex>
        </>
    );
};

export default FirstAvailableDay;
