import { Checkbox, FormControl } from "caspeco-casper-ui";
import { useBookingViewStore } from "hooks";
import { ChangeContactField } from "old/actions/webBookingViewActions";
import React from "react";
import { useTranslation } from "react-i18next";

const NewsletterField = () => {
    const { t } = useTranslation();
    const { createParametersModel } = useBookingViewStore();
    const { contact } = createParametersModel;

    const handleNewsletterToggle = (event: React.ChangeEvent<HTMLInputElement>) =>
        new ChangeContactField("newsletter", event.target.checked);
    return (
        <FormControl>
            <Checkbox
                isChecked={contact.newsletter}
                onChange={handleNewsletterToggle}
                name="newsletter"
                color="primary"
            >
                {t("contact.newsletterSignup")}
            </Checkbox>
        </FormControl>
    );
};

export default NewsletterField;
