import moment from "moment-timezone";

import BookingStatus from "old/models/bookingStatusEnum";
import ChargeTypeEnum from "old/models/chargeTypeEnum";

import { Contact } from "old/models/contact";
import { BookingMenuExternal } from "old/models/bookingMenuExternal";
import { ValidationResult } from "old/common/models/validation/validationResult";
import { RecordFactory } from "old/common/modules/recordFactory";
import { List } from "immutable";
import { WebBookingArticle } from "./webBookingArticle";
import { BookingFlags } from "old/models/bookingFlags";
import { Customer } from "old/models/customer";
import { WebTimeRule } from "./webTimeRule";
import { BookingChange } from "./bookingChange";
import BookingStatusEnum from "old/models/bookingStatusEnum";
import { WaitListOffer, WaitListOfferStatus } from "./waitListOffer";

export interface IWebBooking {
    guid: string;
    unitId: number;
    start: IMoment;
    end: IMoment;
    status: BookingStatus;
    guests: number;
    guestsChildren: number;
    message: string;
    createdDate: IMoment;
    changeDate: IMoment;
    recoupTime: number;
    articles: List<WebBookingArticle>;
    nonTableArticles: List<WebBookingArticle>;
    chargeType: ChargeTypeEnum;
    chargeId: string;
    chargeShouldPayAmount: number;
    chargeShouldPayVat: number;
    chargeAuthorizedAmount: number;
    chargePayedAmount: number;
    chargeRefundedAmount: number;
    chargeStatus: number;
    chargePaymentLinkExpirationDate: IMoment;
    contactId: number;
    contact: Contact;
    customer: Customer;
    menus: List<BookingMenuExternal>;
    isInUpdate: boolean;
    globalBookingNumber: string;
    bookingFlags: BookingFlags;
    language: string;
    cancelAllowedBeforeUtc: IMoment | null;
    webTimeRules: List<WebTimeRule> | null;
    history: List<BookingChange> | null;
    waitListOffers: List<WaitListOffer> | null;
}

const WebBookingRecord = RecordFactory<IWebBooking>({
    guid: "",
    unitId: -1,
    start: moment.invalid(),
    end: moment.invalid(),
    status: -1,
    guests: -1,
    guestsChildren: 0,
    message: "",
    createdDate: moment.invalid(),
    changeDate: moment.invalid(),
    recoupTime: -1,
    articles: List<WebBookingArticle>(),
    nonTableArticles: null,
    chargeType: ChargeTypeEnum.None,
    chargeId: "",
    chargeShouldPayAmount: 0,
    chargeShouldPayVat: 0,
    chargeAuthorizedAmount: 0,
    chargePayedAmount: 0,
    chargeRefundedAmount: 0,
    chargeStatus: 0,
    chargePaymentLinkExpirationDate: moment.invalid(),
    contactId: -1,
    contact: new Contact({}),
    customer: new Customer({}),
    menus: List<BookingMenuExternal>(),
    isInUpdate: false,
    globalBookingNumber: "",
    bookingFlags: -1,
    language: "",
    cancelAllowedBeforeUtc: null,
    webTimeRules: List<WebTimeRule>(),
    history: null,
    waitListOffers: null,
});

export class WebBooking extends WebBookingRecord implements IWebBooking {
    constructor(values?: Partial<IWebBooking>) {
        if (values) {
            values.start = moment(values.start, "YYYY-MM-DDTHH:mm:ss");
            values.end = moment(values.end, "YYYY-MM-DDTHH:mm:ss");
            values.createdDate = moment(values.createdDate, "YYYY-MM-DDTHH:mm:ss");
            values.changeDate = moment(values.changeDate, "YYYY-MM-DDTHH:mm:ss");
            values.chargePaymentLinkExpirationDate = moment(
                values.chargePaymentLinkExpirationDate,
                "YYYY-MM-DDTHH:mm:ss"
            );
            values.cancelAllowedBeforeUtc = values.cancelAllowedBeforeUtc
                ? moment.utc(values.cancelAllowedBeforeUtc, "YYYY-MM-DDTHH:mm:ss")
                : null;
        }

        if (values && values.webTimeRules) {
            values.webTimeRules = List<WebTimeRule>(
                values.webTimeRules.map((h) => new WebTimeRule(h))
            );
        }

        if (values && values.history) {
            values.history = List<BookingChange>(values.history.map((h) => new BookingChange(h)));
        }

        if (values && values.waitListOffers) {
            values.waitListOffers = List<WaitListOffer>(
                values.waitListOffers.map((h) => new WaitListOffer(h))
            );
        }

        if (values && values.articles) {
            values.articles = List<WebBookingArticle>(
                values.articles.map((a) => new WebBookingArticle(a))
            );
        }

        if (values && values.nonTableArticles) {
            values.nonTableArticles = List<WebBookingArticle>(
                values.nonTableArticles.map((a) => new WebBookingArticle(a))
            );
        }

        if (values && values.contact) {
            values.contact = new Contact(values.contact);
        }

        if (values && values.menus) {
            values.menus.forEach((m: any) => (m.guests = values.guests)); //pass on guests to menu, and then to groups, used for validation
            values.menus = List<BookingMenuExternal>(
                values.menus.map((m) => new BookingMenuExternal(m))
            );
        }

        super(values);
    }

    validateErrors(validationResult: ValidationResult): ValidationResult {
        if (this.start >= this.end) {
            validationResult = validationResult.addPropertyError("start", "");
            validationResult = validationResult.addPropertyError("end", "");
            validationResult = validationResult.addGeneralError(
                "booking.booking.validation.invalidInterval"
            );
        }

        const bookingLengthMinutes = this.end.diff(this.start, "minutes");
        if (this.recoupTime > bookingLengthMinutes / 2) {
            validationResult = validationResult.addPropertyError("recoupTime", "");
            validationResult = validationResult.addGeneralError(
                "booking.booking.validation.invalidRecoupTime"
            );
        }

        if (
            this.contactId < 1 ||
            this.contact == null ||
            ((this.contact.fname || "") + (this.contact.lname || "")).trim().length === 0
        ) {
            validationResult = validationResult.addPropertyError(
                "guest",
                "booking.booking.validation.invalidContact"
            );
        }

        if (this.contact && this.contact.hasTempId()) {
            validationResult = validationResult.addPropertyError(
                "guest",
                "booking.booking.validation.createContactPending"
            );
        }

        if (this.guests < 1) {
            validationResult = validationResult.addPropertyError(
                "guests",
                "booking.booking.validation.invalidGuests"
            );
        }

        if (this.guests < this.guestsChildren) {
            validationResult = validationResult.addPropertyError(
                "guests",
                "booking.booking.validation.invalidGuestsChildren"
            );
        }
        return validationResult;
    }

    validateWarnings(validationResult: ValidationResult): ValidationResult {
        return validationResult;
    }

    validate(errors: boolean = true, warnings: boolean = true): ValidationResult {
        let validationResult = new ValidationResult();
        if (errors) {
            validationResult = this.validateErrors(validationResult);
        }
        if (warnings) {
            validationResult = this.validateWarnings(validationResult);
        }

        this.menus.forEach(
            (m) => (validationResult = validationResult.mergeValidationResult(m.validate()))
        );

        return validationResult;
    }

    validateMenus(errors: boolean = true, warnings: boolean = true): ValidationResult {
        let validationResult = new ValidationResult();
        this.menus.forEach(
            (m) => (validationResult = validationResult.mergeValidationResult(m.validate()))
        );

        return validationResult;
    }

    isWaitList() {
        if (this.history) {
            const lastStatusBeforeCancel = this.history
                .filter((x) => x.status !== BookingStatusEnum.Canceled)
                .last().status;
            return (
                this.status === BookingStatusEnum.WaitList ||
                lastStatusBeforeCancel === BookingStatusEnum.WaitList
            );
        }
        return this.status === BookingStatusEnum.WaitList;
    }

    hasBookingPassed() {
        return moment() > this?.start;
    }

    isBookingCanceled() {
        return this.status === BookingStatusEnum.Canceled;
    }

    cancelNotAllowed() {
        return (
            this.cancelAllowedBeforeUtc &&
            moment() > this.cancelAllowedBeforeUtc &&
            !this.isWaitList()
        );
    }

    hasWaitListOffers() {
        return this.waitListOffers && this.waitListOffers.count() > 0;
    }

    hasAvailableWaitListOffers() {
        return (
            this.waitListOffers &&
            this.waitListOffers.count() > 0 &&
            this.waitListOffers.some((w) => w.offerStatus === 1)
        );
    }

    getAvailableWaitListOffer() {
        return this.waitListOffers?.find(
            (offer) => offer.offerStatus === WaitListOfferStatus.Available
        );
    }

    isWaitListOfferLocked() {
        const webTimeRule = this.webTimeRules?.first();
        if (!webTimeRule) return false;
        const ruleStartTime = moment(this.start)
            .startOf("day")
            .add(webTimeRule?.startTime, "hours");
        const offerStartTime = this.getAvailableWaitListOffer()?.startTime;

        if (!offerStartTime) return false;

        const availableUntil =
            webTimeRule?.lockPerTime || webTimeRule?.lockTime === 0
                ? offerStartTime.clone().subtract(webTimeRule?.lockTime, "minutes")
                : ruleStartTime.clone().subtract(webTimeRule?.lockTime, "minutes");
        const now = moment();

        return now.isAfter(availableUntil);
    }
}
