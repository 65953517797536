import { ScaleFade } from "@chakra-ui/react";
import { useCancelBooking, useExternalBookingSettings, useWebBooking } from "api/apiHooks";
import { Button, Stack, ThemeSpaceVariable, useDisclosure } from "caspeco-casper-ui";
import CancelModal from "components/cancel-modal";
import { useBookingIdParam, useConfirmWaitListBooking } from "hooks";
import { t } from "i18next";
import { trackEvent } from "logging/insights";
import { Reset } from "old/actions/webBookingViewActions";
import BookingStatusEnum from "old/models/bookingStatusEnum";
import { useError } from "context/error-context";
import { ErrorView } from "pages/error-view";
import { Articles } from "pages/booking-details/articles";
import { BookingSummary } from "pages/booking-details/booking-summary/booking-summary";
import BookingStatus from "pages/booking-details/booking-status/booking-status";
import CancelButton from "pages/booking-details/cancel-booking/cancel-button";
import ResturantInformation from "pages/booking-details/resturant-information";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getUrlParams } from "utils";
import BookMoreButton from "./book-more-button";
import BookingOverview from "./booking-overview";
import BookingDetailsSkeleton from "./booking-details-skeleton";

export default function BookingDetails() {
    const { isLoading: isLoadingBooking, data: webBooking, isError } = useWebBooking();
    const { isLoading: isLoadingSettings } = useError();
    const externalBookingSettings = useExternalBookingSettings();
    const [notPossibleToCancel, setNotPossibleToCancel] = useState(false);
    const bookingId = useBookingIdParam();
    const [isCanceled, setIsCanceled] = useState(false);
    const { mutate, isSuccess, isLoading } = useCancelBooking(bookingId);
    const { mutate: confirmMutate, isLoading: confirmIsLoading } = useConfirmWaitListBooking(
        bookingId,
        webBooking
    );
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [fadeIn, setFadeIn] = useState(false);
    useEffect(() => {
        setFadeIn(true);
    }, [webBooking]);

    useEffect(() => {
        if (webBooking?.status && webBooking?.status !== BookingStatusEnum.Canceled) {
            const isCancel = searchParams.get("isCancel") === "true";
            const queryParams = new URLSearchParams(window.location.search);
            if (isCancel) {
                queryParams.delete("isCancel");
                const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
                navigate(newUrl, { replace: true });
                onOpen();
            }
        }
    }, [navigate, onOpen, searchParams, webBooking?.status]);

    useEffect(() => {
        if (isSuccess) {
            setIsCanceled(true);
        }
    }, [isSuccess]);

    const onCancelBookingClick = () => {
        mutate();
        onClose();
        trackEvent("CancelBookingClicked", {
            guests: webBooking.guests,
            children: webBooking.guestsChildren,
            date: webBooking.start.toISOString(),
            isWaitingList: webBooking.isWaitList(),
        });
    };

    const location = useLocation();
    const isFinal = location.state?.isFinal;
    useEffect(() => {
        const handlePopState = async () => {
            const params = getUrlParams();
            const { system } = params;
            let { unitId } = params;
            if (isFinal) {
                unitId = unitId || webBooking?.unitId;

                navigate(`/${system}/${unitId}`);
                new Reset();
            }
        };
        window.addEventListener("popstate", handlePopState);
        return () => {
            setTimeout(() => {
                window.removeEventListener("popstate", handlePopState);
            }, 0);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webBooking]);

    const shouldShowCancelBtn =
        !isFinal &&
        webBooking?.status !== BookingStatusEnum.Canceled &&
        !isSuccess &&
        !notPossibleToCancel &&
        !webBooking?.hasBookingPassed() &&
        !webBooking?.isWaitListOfferLocked();

    if (isError) return <ErrorView />;
    if (isLoadingSettings || isLoadingBooking) return <BookingDetailsSkeleton />;

    const bookingCanceled = isCanceled || webBooking.isBookingCanceled();
    const shouldShowBookingSummary =
        !bookingCanceled && !webBooking.hasBookingPassed() && !webBooking.isWaitListOfferLocked();
    const disableOverview =
        !shouldShowBookingSummary ||
        (webBooking.waitListOffers?.count() > 0 && !webBooking.hasAvailableWaitListOffers());
    const shouldShowConfrimBookingBtn =
        webBooking?.hasAvailableWaitListOffers() &&
        !disableOverview &&
        !webBooking?.isWaitListOfferLocked();
    const shouldHideBoookingOverview =
        webBooking.isWaitListOfferLocked() && !webBooking.hasBookingPassed();
    return (
        <>
            <ScaleFade initialScale={0.9} in={fadeIn} transition={{ enter: { duration: 0.5 } }}>
                <Stack spacing={ThemeSpaceVariable.Large}>
                    <BookingStatus
                        isFinal={isFinal}
                        isCanceled={isCanceled}
                        isSuccess={isSuccess}
                        setNotPossibleToCancel={setNotPossibleToCancel}
                    />
                    {!shouldHideBoookingOverview && (
                        <BookingOverview disableOverview={disableOverview} />
                    )}
                    {shouldShowConfrimBookingBtn && (
                        <Button
                            variant="primary"
                            isLoading={confirmIsLoading}
                            onClick={() => confirmMutate()}
                        >
                            {t("waitList.confirmBooking")}
                        </Button>
                    )}
                    {shouldShowBookingSummary && <BookingSummary webBooking={webBooking} />}
                    {webBooking?.nonTableArticles.size > 0 && (
                        <Articles articles={webBooking?.nonTableArticles} />
                    )}
                    {shouldShowCancelBtn ? (
                        <CancelButton onClick={onOpen} isLoading={isLoading} />
                    ) : (
                        <BookMoreButton unitId={webBooking.unitId} webBooking={webBooking} />
                    )}
                    {externalBookingSettings && (
                        <ResturantInformation settings={externalBookingSettings} />
                    )}
                </Stack>
                <CancelModal
                    isOpen={isOpen}
                    handleCancelModalModalClose={onClose}
                    handleCancelModalCancel={onCancelBookingClick}
                />
            </ScaleFade>
        </>
    );
}
