import { List, Map } from "immutable";
import moment from "moment-timezone";
import * as ArticleStockActions from "old/actions/articleStockActions";
import * as WebBookingActions from "old/actions/webBookingActions";
import * as WebBookingViewActions from "old/actions/webBookingViewActions";
import { translate as t } from "old/common/mixins/localeHelper";
import { ValidationResult } from "old/common/models/validation/validationResult";
import { LocaleStore } from "old/framework/stores/localeStore";
import { ArticleStock } from "old/models/articleStock";
import { BackFromPaymentTerminalParameters } from "old/models/backFromPaymentTerminalParameters";
import {
    BookingEvent,
    FinalizeWebBookingEvent,
    WebBookingEventContact,
} from "old/models/bookingEvent";
import ExternalBookingSettingsAPI from "old/models/externalBookingSettingsAPI";
import { WebBooking } from "old/models/webBooking";
import webBookingAPI, { Promises, getActivePromise } from "old/models/webBookingAPI";
import { WebBookingCancelParameters } from "old/models/webBookingCancelParameters";
import { WebBookingCreateParameters } from "old/models/webBookingCreateParameters";
import { WebBookingCreateParametersRuleTime } from "old/models/webBookingCreateParametersRuleTime";
import { WebBookingCustomer } from "old/models/webBookingCustomer";
import { WebBookingFinalizeParameters } from "old/models/webBookingFinalizeParameters";
import { WebBookingTimeRuleUnit } from "old/models/webBookingTimeRuleUnit";
import i18n from "translations/config/i18n";

export async function loadContactCustomer(customerFriendlyId: string, system: string) {
    try {
        const contactCustomerConnection = await webBookingAPI.loadContactCustomer(
            customerFriendlyId,
            system
        );
        new WebBookingActions.LoadedCustomerContactSuccess(contactCustomerConnection);
    } catch (ex) {
        new WebBookingActions.LoadedCustomerContactFailed();
    }
}

export async function loadAvailableTimes(
    dates: List<IMoment>,
    unitIds: List<number>,
    sectionIds: List<number>,
    system: string,
    includeUnavailability: boolean
) {
    try {
        new WebBookingActions.LoadingAvailableTimes(dates, unitIds, sectionIds);
        const availableTimes = await webBookingAPI.loadAvailableTimes(
            dates,
            unitIds,
            sectionIds,
            system,
            includeUnavailability
        );
        new WebBookingActions.LoadedAvailableTimesSuccessfully(
            availableTimes,
            dates,
            unitIds,
            sectionIds
        );
    } catch (ex) {
        new WebBookingActions.LoadingAvailableTimesFailed(dates, unitIds, sectionIds);
    }
}

export async function loadDayStates(
    fromDate: IMoment,
    toDate: IMoment,
    unitIds: List<number>,
    sectionIds: List<number>,
    system: string
) {
    try {
        new WebBookingActions.LoadingWebBookingDayStates(fromDate, toDate, unitIds);
        const dayStates = await webBookingAPI.loadDayStates(
            fromDate,
            toDate,
            unitIds,
            sectionIds,
            system
        );
        new WebBookingActions.LoadedDayStatesSuccessfully(dayStates, fromDate, toDate, unitIds);
        return dayStates;
    } catch (ex) {
        new WebBookingActions.LoadingDayStatesFailed(fromDate, toDate, unitIds);
    }
}

export async function loadArticleStocks(webBooking: WebBooking, systemName: string) {
    if (webBooking && webBooking.menus) {
        const articleIds: number[] = [];
        webBooking.menus.map((m) =>
            m.groups.map((g) =>
                g.items.map((x) => {
                    articleIds.push(x.articleID);
                })
            )
        );
        const validityDate = webBooking.start; //TODO: if several menus on different times, check stock at these times
        const articleStocks = await webBookingAPI.loadArticleStocks(
            articleIds,
            validityDate,
            systemName,
            false
        );
        new ArticleStockActions.LoadByArticleIdSuccess(
            articleIds,
            Map<number, Map<number, ArticleStock>>(
                articleStocks.groupBy((aS) => aS.articleId).toMap()
            )
        );
    }
}

// Not used?
export async function createWebBooking(
    webBookingCreateParameters: WebBookingCreateParameters,
    systemName: string
) {
    try {
        webBookingCreateParameters = <WebBookingCreateParameters>(
            webBookingCreateParameters.setIn(
                ["times", 0, "start"],
                (<IMoment>webBookingCreateParameters.getIn(["times", 0, "start"])).format(
                    "YYYY-MM-DD[T]HH:mm:ss"
                )
            )
        );

        webBookingCreateParameters = <WebBookingCreateParameters>(
            webBookingCreateParameters.setIn(
                ["times", 0, "end"],
                (<IMoment>webBookingCreateParameters.getIn(["times", 0, "end"])).format(
                    "YYYY-MM-DD[T]HH:mm:ss"
                )
            )
        );

        new WebBookingViewActions.CreateWebBooking();
        const webBooking = await webBookingAPI.createBooking(
            webBookingCreateParameters,
            systemName
        );
        new WebBookingViewActions.WebBookingCreatedSuccessfully(webBooking);
    } catch (ex) {
        new WebBookingViewActions.CreateWebBookingFailed(ex);
    }
}

export async function reserveWebBooking(
    timeSetDate: IMoment,
    guests: number,
    guestsChildren: number,
    times: List<WebBookingTimeRuleUnit>,
    systemName: string,
    unitId: number,
    sectionIds: number[],
    debug: boolean,
    hostURL?: string,
    isWaitList: boolean = false
) {
    const reservationPromise = getActivePromise(Promises.Reservation);
    if (reservationPromise) {
        const reservation = await reservationPromise;
        await cancelWebBooking(reservation.guid, systemName);
    }

    new WebBookingActions.ReserveBooking();

    const ruleTimes = times
        .map(
            (time) =>
                new WebBookingCreateParametersRuleTime({
                    ruleId: time.ruleId,
                    start: time.start,
                    availableUntil: time.availableUntil,
                })
        )
        .toList();

    const locale = i18n.language;
    const webBookingCreateParameters = new WebBookingCreateParameters({
        timeSetDate,
        guests,
        guestsChildren,
        times: ruleTimes,
        lang: locale,
        logMessage: t("changeLogMessage.pendingLogMessage") + (hostURL ? ` (URL: ${hostURL})` : ""),
        isPending: true, // Will update status and contact once it is created
        isWaitList: isWaitList,
    });
    if (webBookingCreateParameters.validate().isValid()) {
        let webBooking;
        try {
            webBooking = await webBookingAPI.createBooking(webBookingCreateParameters, systemName);
        } catch (ex) {
            loadAvailableTimes(
                List([times.first().start]),
                List([unitId]),
                List<number>(sectionIds),
                systemName,
                debug
            );
        }

        loadArticleStocks(webBooking, systemName);
        new WebBookingActions.ReserveBookingSuccess(webBooking);
    }
}

export async function cancelWebBooking(guid: string, systemName: string) {
    const webBookingCancelParameters = new WebBookingCancelParameters({});
    try {
        await webBookingAPI.cancelWebBooking(guid, webBookingCancelParameters, systemName);
        new WebBookingActions.CancelBookingSuccess();
    } catch (ex) {
        new WebBookingActions.CancelBookingFail();
    }
}

export async function finalizeWebBooking(
    guid: string,
    webBookingCreateParameters: WebBookingCreateParameters,
    systemName: string,
    firstEventTime: IMoment,
    handleEvent: (event: BookingEvent) => void,
    navigate: (guid: string) => void,
    lang: string
) {
    if (
        webBookingCreateParameters.isCompanyCustomer &&
        webBookingCreateParameters.contact.customerName
    ) {
        if (webBookingCreateParameters.wishToBeInvoiced) {
            webBookingCreateParameters = webBookingCreateParameters.set(
                "customer",
                new WebBookingCustomer({
                    name: webBookingCreateParameters.contact.customerName,
                    orgNr: webBookingCreateParameters.contact.organizationCode,
                    email: webBookingCreateParameters.contact.invoiceEmail,
                    invoiceStreetaddress: webBookingCreateParameters.contact.invoiceStreetAddress,
                })
            );
            webBookingCreateParameters = webBookingCreateParameters.set("wishToBeInvoiced", true);
            webBookingCreateParameters = webBookingCreateParameters.set(
                "invoiceMarking",
                webBookingCreateParameters.contact.invoiceMarking
            );
        } else {
            webBookingCreateParameters = webBookingCreateParameters.set(
                "customer",
                new WebBookingCustomer({
                    name: webBookingCreateParameters.contact.customerName,
                })
            );
        }
    }

    const webBookingFinalizeParameters = new WebBookingFinalizeParameters({
        customer: webBookingCreateParameters.customer,
        contact: webBookingCreateParameters.contact,
        lang,
        message: webBookingCreateParameters.message,
        invoiceMarking: webBookingCreateParameters.invoiceMarking,
        wishToBeInvoiced: webBookingCreateParameters.wishToBeInvoiced,
    });

    try {
        new WebBookingActions.FinalizeWebBooking();
        const webBooking = await webBookingAPI.finalizeBooking(
            guid,
            webBookingFinalizeParameters,
            systemName
        );
        new WebBookingActions.FinalizeWebBookingSuccess(webBooking);
        handleEvent(
            new FinalizeWebBookingEvent(
                webBooking.createdDate,
                webBooking.start,
                webBooking.contact.returning ? "returning" : "new",
                webBooking.guests,
                webBooking.guests - webBooking.guestsChildren,
                webBooking.guestsChildren,
                new WebBookingEventContact(
                    webBooking.contact.fname,
                    webBooking.contact.lname,
                    webBooking.contact.email,
                    webBooking.contact.mobile,
                    webBooking.contact.newsletter,
                    webBooking.contact.allowStoringForever
                ),
                webBooking.articles.map((a) => a.articleGroupName).toArray(),
                moment().diff(firstEventTime, "milliseconds")
            )
        );
        navigate(guid);
    } catch (ex) {
        new WebBookingActions.FinalizeWebBookingFailed();
    }
}

export async function backFromPaymentTerminal(
    guid: string,
    backFromPaymentTerminalParameters: BackFromPaymentTerminalParameters,
    systemName: string,
    handleEvent: (event: BookingEvent) => void
) {
    try {
        const webBooking = await webBookingAPI.backFromTerminal(
            guid,
            backFromPaymentTerminalParameters,
            systemName
        );
        new WebBookingActions.BackFromTerminalSuccessfully(webBooking);
        handleEvent(
            new FinalizeWebBookingEvent(
                webBooking.createdDate,
                webBooking.start,
                webBooking.contact.returning ? "returning" : "new",
                webBooking.guests,
                webBooking.guests - webBooking.guestsChildren,
                webBooking.guestsChildren,
                new WebBookingEventContact(
                    webBooking.contact.fname,
                    webBooking.contact.lname,
                    webBooking.contact.email,
                    webBooking.contact.mobile,
                    webBooking.contact.newsletter,
                    webBooking.contact.allowStoringForever
                ),
                webBooking.articles.map((a) => a.articleGroupName).toArray(),
                0 // TODO: pass through "firstEventTime" from the payment terminal?
            )
        );

        ExternalBookingSettingsAPI.load(webBooking.unitId, systemName);
    } catch (e) {
        if (e.error === undefined) {
            new WebBookingActions.FinalizeWebBookingFailed();
        } else {
            const ex = new ValidationResult(e.error.validationResult);
            if (
                !ex.generalErrors.find(
                    (x) => x.message === "webBooking.paymentErrors.DoubleBookingException"
                )
            ) {
                webBookingAPI.loadWebBooking(guid, systemName);
            }
            new WebBookingActions.BackFromTerminalFailed(ex);
        }
    }
}
