import { Heading, Stack, Text, ThemeFontSizeVariable } from "caspeco-casper-ui";
import EmailLink from "components/links/email-link";
import { PhoneNumberLink } from "components/links/phone-number-link";
import { SpaceBetween } from "layout/spaceBetween";
import { ExternalBookingSettings } from "old/models/externalBookingSettings";
import { useTranslation } from "react-i18next";

export type ResturantInformationProps = {
    settings: ExternalBookingSettings;
};

export default function ResturantInformation({ settings }: ResturantInformationProps) {
    const { t } = useTranslation();
    const { unitsMetaData, email, address } = settings;
    return (
        <Stack>
            <Heading as="h4" fontWeight={"medium"} fontSize={ThemeFontSizeVariable.Large}>
                {t("resturangInformation.contactInformation")}
            </Heading>
            <SpaceBetween>
                <Text>{t("resturangInformation.place")}</Text>
                <Text textAlign="right">{unitsMetaData.name}</Text>
            </SpaceBetween>
            <SpaceBetween>
                <Text>{t("resturangInformation.address")}</Text>
                <Text textAlign="right">{address}</Text>
            </SpaceBetween>
            <SpaceBetween>
                <Text>{t("resturangInformation.phone")}</Text>
                <PhoneNumberLink />
            </SpaceBetween>
            <SpaceBetween>
                <Text>{t("resturangInformation.replyEmail")}</Text>
                <EmailLink email={email} />
            </SpaceBetween>
        </Stack>
    );
}
