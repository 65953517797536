import { Box } from "caspeco-casper-ui";
import {
    ContactInformation,
    EditSelectionButtonGroup,
    SelectChildrenAmount,
    SelectDate,
    SelectGuestsAmount,
    SelectTime,
} from "components/selection-components";
import Spinner from "components/spinner/spinner";
import "global.less";
import { context as BookingViewContext } from "hooks/bookingView";
import { List } from "immutable";
import { trackEvent } from "logging/insights";
import moment from "moment-timezone";
import {
    backFromPaymentTerminal,
    finalizeWebBooking,
    loadContactCustomer,
    loadDayStates,
} from "old/actions/webBookingActionCreators";
import * as ViewActions from "old/actions/webBookingViewActions";
import { Reset } from "old/actions/webBookingViewActions";
import { IsoCountryCode } from "old/application/models/management/user";
import { BackFromPaymentTerminalParameters } from "old/models/backFromPaymentTerminalParameters";
import {
    default as ViewStore,
    default as webBookingViewStore,
} from "old/stores/webBookingViewStore";
import { useError } from "context/error-context";
import { ErrorView } from "pages/error-view";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import i18n from "translations/config/i18n";
import { getUrlParams, handleEvent } from "utils";
import { useExternalBookingSettings } from "../api/apiHooks";
import { Promises, getActivePromise } from "../old/models/webBookingAPI";

//TODO:Förenkla init view store
const preLoadDayStates = async (unitId: number, sectionIds: number[], system: string) => {
    const today = moment().local().startOf("day");

    const startDate = today;
    const endDate = today.clone().endOf("month");

    await loadDayStates(startDate, endDate, List([unitId]), List(sectionIds), system);
};
const initializeViewStore = async () => {
    if (ViewStore.isInitialized()) return;
    const {
        customerFriendlyId,
        system,
        unitId,
        sectionIds,
        chargeId,
        webBookingId,
        paymentLink,
        initialStep,
        advanced,
        onlineorder,
    } = getUrlParams();

    if (customerFriendlyId) {
        loadContactCustomer(customerFriendlyId, system);
    }

    if (unitId > 0 && system !== null) {
        preLoadDayStates(unitId, sectionIds, system);
    }

    if (chargeId !== null && webBookingId !== null && system !== null) {
        backFromPaymentTerminal(
            webBookingId,
            new BackFromPaymentTerminalParameters({
                bookingChargeId: chargeId,
            }),
            system,
            handleEvent
        );
    }

    new ViewActions.Initialize(
        unitId,
        sectionIds,
        webBookingId,
        system,
        chargeId,
        paymentLink,
        initialStep,
        advanced,
        onlineorder
    );
};
window.addEventListener("popstate", () => {
    new Reset();
});
const isInitialized = false;
// TODO: move this to a better place!
// TODO: we should be able to remove this once we have a better way to handle this.
async function init() {
    if (isInitialized) return;

    const { system, height, inIFrame, onlineorder } = getUrlParams();

    const systemParts = system?.split("_");
    const [SYSTEM_PREFIX] = systemParts ?? [];

    if (
        SYSTEM_PREFIX &&
        !Object.values(IsoCountryCode).includes(SYSTEM_PREFIX.toLocaleUpperCase())
    ) {
        // Antiviruses scanning emails, ROT-13 encrypts system- and other GET-parameters resulting in spammy requests to MARC
        throw new Error(`Invalid system prefix in: ${system}`);
    }

    const root = document.getElementsByTagName("html")[0];
    if (!height && !root.className.includes("scrollFix")) {
        root.className += " scrollFix";
    }

    if (inIFrame) {
        document.body.style.margin = "3px";
        document.getElementsByTagName("body")[0].classList.add("transparent");
    }

    if (onlineorder) {
        document.title = "Caspeco Onlineorder";
    }

    initializeViewStore();
}
init();
export default function BookingWizard() {
    const [state, setState] = useState(webBookingViewStore.getState());
    const externalBookingSettings = useExternalBookingSettings();

    const { systemId, unitId } = useParams();
    const { error, isLoading } = useError();
    const navigate = useNavigate();

    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const { system, inIFrame } = getUrlParams();

    useEffect(() => {
        const onStoreChange = () => {
            setState(webBookingViewStore.getState());
        };
        webBookingViewStore.onChange(onStoreChange);
        return () => {
            webBookingViewStore.offChange(onStoreChange);
        };
    }, []);

    useEffect(() => {
        if (systemId && unitId) {
            localStorage.setItem("lastKnownSystemId", systemId);
            localStorage.setItem("lastKnownUnitId", unitId);
        }
    }, [systemId, unitId]);

    const handleFinalizeNavigation = (guid: string) => {
        window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
        navigate(`/booking/${system}/${guid}`, {
            state: { isFinal: true },
        });
    };

    const handleFinalizeWebBooking = async () => {
        const activeReservation = getActivePromise(Promises.Reservation);
        const activeCancel = getActivePromise(Promises.Cancel);

        let bookingGuid = state.bookingReservation.guid;

        if (activeCancel) {
            await activeCancel;
        }
        if (activeReservation) {
            const reservationPromise = await activeReservation;
            bookingGuid = reservationPromise.guid;
        }

        const startTime = new Date();

        finalizeWebBooking(
            bookingGuid,
            state.createParametersModel,
            system,
            state.firstEventTime,
            handleEvent,
            handleFinalizeNavigation,
            i18n.language
        )
            .then(() => {
                const endTime = new Date();
                const loadTime = endTime.getTime() - startTime.getTime();
                trackEvent("BookingFinalized", {
                    guests: state.createParametersModel.guests,
                    children: state.createParametersModel.guestsChildren,
                    date: state.createParametersModel.times.first().start.toISOString(),
                    isWaitingList: state.createParametersModel.isWaitList,
                    ms: loadTime,
                });
            })
            .catch(() => {
                const endTime = new Date();
                const loadTime = endTime.getTime() - startTime.getTime();
                trackEvent("BookingFinalizeFailed", {
                    guests: state.createParametersModel.guests,
                    children: state.createParametersModel.guestsChildren,
                    date: state.createParametersModel.times.first().start.toISOString(),
                    isWaitingList: state.createParametersModel.isWaitList,
                    ms: loadTime,
                });
            });
    };

    const finalizeBooking = () => {
        document.body.scrollTop = 0;
        window.parent.postMessage("scroll2top", "*");
        new ViewActions.NextStep(handleFinalizeWebBooking);
    };

    let containerClass = "webBookingContainer";
    if (inIFrame) containerClass += " inIFrame";

    if (isLoading) {
        return <Spinner />;
    }

    if (error) {
        return <ErrorView />;
    }
    const { createParametersModel, date, selectedTimes } = state;
    const { guests, guestsChildren } = createParametersModel;
    const { activateWebChildren } = externalBookingSettings.unitsMetaData;

    return (
        <Box className={containerClass}>
            <BookingViewContext.Provider value={state}>
                <EditSelectionButtonGroup />
                {!guests ? (
                    <SelectGuestsAmount />
                ) : activateWebChildren && guestsChildren === null ? (
                    <SelectChildrenAmount />
                ) : !date ? (
                    <SelectDate selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} />
                ) : !selectedTimes.size ? (
                    <SelectTime />
                ) : (
                    <ContactInformation finalizeBooking={finalizeBooking} />
                )}
            </BookingViewContext.Provider>
        </Box>
    );
}
