import {
    Box,
    Flex,
    Icon,
    Icons,
    Image,
    Text,
    ThemeBorderVariable,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeRadiusVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";

interface IEditChoiceButton {
    icon: Icons;
    text: string;
    onClick: (step: number) => void;
}

const EditSelectionButton: React.FC<IEditChoiceButton> = ({ icon, text, onClick }) => {
    return (
        <Flex
            as="button"
            onClick={onClick}
            userSelect="none"
            flexDirection="row"
            borderLeft={ThemeBorderVariable.XSmall}
            borderBottomLeftRadius={ThemeRadiusVariable.Small}
            borderTopLeftRadius={ThemeRadiusVariable.Small}
            borderRight={ThemeBorderVariable.XSmall}
            borderBottomRightRadius={ThemeRadiusVariable.Small}
            borderTopRightRadius={ThemeRadiusVariable.Small}
            borderTop={ThemeBorderVariable.XSmall}
            borderBottom={ThemeBorderVariable.XSmall}
            borderColor={ThemeColorVariable.OnSurfaceBorder}
            height="48px"
            overflow="hidden"
            alignItems="center"
            gap={ThemeSpaceVariable.Small}
            paddingLeft={ThemeSpaceVariable.Medium}
            background={ThemeColorVariable.Surface}
            cursor="pointer"
        >
            <Box>
                <Icon icon={icon} fontSize={ThemeFontSizeVariable.XLarge} />
            </Box>
            <Text flex="1" as="span" fontWeight="500" textAlign="left">
                {text}
            </Text>

            <Box className="editPenWrapper" paddingRight={ThemeSpaceVariable.Medium}>
                <Icon icon={Icons.Edit} fontSize={ThemeFontSizeVariable.XLarge} />
            </Box>
        </Flex>
    );
};

export default EditSelectionButton;
