import { Box, Button, Flex, ThemeColorVariable, ThemeSpaceVariable } from "caspeco-casper-ui";
import { List } from "immutable";
import { WebBookingTimeRuleUnit } from "old/models/webBookingTimeRuleUnit";

interface IWebBookingTimeProps {
    time: WebBookingTimeRuleUnit;
    onTimeClick: (time: WebBookingTimeRuleUnit) => void;
    selectedTimes: List<WebBookingTimeRuleUnit>;
}

export default function WaitlistTime({ onTimeClick, time, selectedTimes }: IWebBookingTimeProps) {
    const isSelected = selectedTimes.indexOf(time) !== -1;
    return (
        <Button
            key={time.start.format("HH:mm")}
            onClick={() => onTimeClick(time)}
            isSelected={isSelected}
            variant="card"
            size="lg"
            w="full"
            px={ThemeSpaceVariable.Small}
        >
            <Flex direction="column" align="center">
                <Box mt="6px">{time.start.format("HH:mm")}</Box>
                <Box
                    as="span"
                    w="6px"
                    h="6px"
                    background={ThemeColorVariable.Warning}
                    borderRadius="full"
                />
            </Flex>
        </Button>
    );
}
