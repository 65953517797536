import { RecordFactory } from "old/common/modules/recordFactory";

export interface IBackFromPaymentTerminalParameters {
    bookingChargeId: string;
    disableAllConfirmationMessages: boolean;
    disableEmailConfirmationMessage: boolean;
    disableSmsConfirmationMessage: boolean;
    logMessage: string;
}

const BackFromPaymentTerminalParametersRecord = RecordFactory<IBackFromPaymentTerminalParameters>({
    bookingChargeId: null,
    disableAllConfirmationMessages: false,
    disableEmailConfirmationMessage: false,
    disableSmsConfirmationMessage: false,
    logMessage: "",
});

export class BackFromPaymentTerminalParameters
    extends BackFromPaymentTerminalParametersRecord
    implements IBackFromPaymentTerminalParameters
{
    constructor(values?: Partial<IBackFromPaymentTerminalParameters>) {
        super(values);
    }
}
