import {
    Button,
    Flex,
    Heading,
    Icon,
    Icons,
    Stack,
    Text,
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { useExternalBookingSettings, useWebBooking } from "hooks";
import { t } from "i18next";
import { Trans } from "react-i18next";

interface IOfferStatusProps {
    title: string;
    message: string;
    locked?: boolean;
    lockTime?: number;
}

const OfferAvailable = ({ title, message }: IOfferStatusProps) => (
    <Flex direction="column" alignItems="left">
        <Heading as="h1" fontWeight="700" textAlign="left">
            {title}
        </Heading>
        <Text maxW="496" textAlign="left" fontSize={ThemeFontSizeVariable.Large}>
            {message}
        </Text>
    </Flex>
);

const OfferNotAvailable = ({ title, message, locked, lockTime }: IOfferStatusProps) => {
    const phoneNumber = useExternalBookingSettings()?.phoneNumber;
    const hours = lockTime && Math.floor(lockTime / 60);
    return (
        <Flex direction="column" alignItems="center">
            <Flex
                backgroundColor={ThemeColorVariable.Error}
                padding="2px"
                borderRadius="50%"
                marginBottom="16px"
                align="center"
                justify="center"
                w="64px"
                h="64px"
            >
                <Icon icon={Icons.Remove} fontSize="50px" color="white" />
            </Flex>
            <Heading as="h1" fontWeight="500" textAlign="center" mb={ThemeSpaceVariable.Small}>
                {title}
            </Heading>
            <Text maxW="496" textAlign="center" fontSize={ThemeFontSizeVariable.Large}>
                {locked ? (
                    <Trans i18nKey="onlineBookingPossibleUntil">
                        {t("waitList.onlineBookingPossibleUntil", {
                            hours: hours.toString(),
                            phoneNumber,
                        })}

                        <Button variant="link" href={`tel:${phoneNumber}`} />
                    </Trans>
                ) : (
                    message
                )}
            </Text>
        </Flex>
    );
};

const WaitListOffer = () => {
    const webBooking = useWebBooking().data;
    const hasAvailableOffers = webBooking?.hasAvailableWaitListOffers();
    const waitListOfferLocked = webBooking.isWaitListOfferLocked();
    const showWaitListOffer = hasAvailableOffers && !waitListOfferLocked;
    return (
        <Stack alignItems="center" gap={ThemeSpaceVariable.Medium}>
            {showWaitListOffer ? (
                <OfferAvailable
                    title={t("waitList.offerAvailableTitle")}
                    message={t("waitList.offerAvailableMessage")}
                />
            ) : (
                <OfferNotAvailable
                    title={t("waitList.offerNotAvailableTitle")}
                    message={t("waitList.offerNotAvailableMessage")}
                    locked={waitListOfferLocked}
                    lockTime={webBooking.webTimeRules.get(0)?.lockTime}
                />
            )}
        </Stack>
    );
};
export default WaitListOffer;
