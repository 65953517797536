import { InputGroup } from "@chakra-ui/react";
import { useExternalBookingSettings } from "api/apiHooks";
import {
    Alert,
    Box,
    Button,
    FormControl,
    Grid,
    Icons,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { trackEvent } from "logging/insights";
import { ChangeModelField } from "old/actions/webBookingViewActions";
import { SafeHTML } from "components/safe-HTML";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SelectionHeader from "./selection-header";
import { getUrlParams, setSearch } from "utils";

function trackLoadingTime() {
    if ("__APP_START_TIME__" in window && "__IS_TRACKED__" in window && !window.__IS_TRACKED__) {
        window.__IS_TRACKED__ = true;
        const now = new Date();
        const loadTime = now.getTime() - window.__APP_START_TIME__.getTime();
        trackEvent("AppLoadTime", {
            ms: loadTime,
        });
    }
}
export const SelectGuestsAmount = () => {
    const [tooManyGuests, setTooManyGuests] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = getUrlParams();

    const externalBookingSettings = useExternalBookingSettings();
    const { maxWebGuests } = externalBookingSettings.unitsMetaData;

    useEffect(trackLoadingTime, []);

    const handleGuestChange = (guests: number) => {
        trackEvent("GuestsSelected", {
            count: guests,
        });
        if (guests > maxWebGuests) {
            setTooManyGuests(true);
            return;
        }
        new ChangeModelField("guests", guests);

        if (params.guests !== guests) {
            setSearch(navigate, "guests", guests.toString());
        }
    };

    useEffect(() => {
        if (maxWebGuests >= 0 && params.guests !== null) {
            handleGuestChange(params.guests);
        }
    }, [maxWebGuests]);

    const mobileColumns = "repeat(3, 1fr)"; // För mobile skärmar
    const smallScreenColumns = "repeat(4, 1fr)"; // För små skärmar

    return (
        <FormControl key="guest-choice" id="guestChoice">
            <SelectionHeader id="guests" icon={Icons.UserGroup} text={t("start.pickGuestsHint")} />
            <InputGroup>
                <Grid
                    templateColumns={{
                        base: mobileColumns,
                        sm: smallScreenColumns,
                    }}
                    gap={ThemeSpaceVariable.Small}
                    width="100%"
                >
                    {[...Array(maxWebGuests + 1).keys()]
                        .map((n) => n + 1)
                        .map((guests) => {
                            return (
                                <Button
                                    key={guests}
                                    variant="card"
                                    size="lg"
                                    display="flex"
                                    w="100%"
                                    alignItems="center"
                                    justifyContent="center"
                                    onClick={() => {
                                        handleGuestChange(guests);
                                    }}
                                >
                                    {guests}
                                    {guests === maxWebGuests + 1 && "+"}
                                </Button>
                            );
                        })}
                </Grid>
            </InputGroup>
            {tooManyGuests && (
                <Box mt={ThemeSpaceVariable.Medium}>
                    <Alert type="info" iconVerticalAlign={"flex-start"}>
                        <SafeHTML
                            className="textFromSettings"
                            element="div"
                            options={{
                                allowedTags: ["a", "br"],
                                allowedSchemes: ["tel", "mailto", "https"],
                            }}
                            html={externalBookingSettings.unitsMetaData.messageExceededGuests}
                        />
                    </Alert>
                </Box>
            )}
        </FormControl>
    );
};
