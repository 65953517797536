import { Flex, Heading, ThemeSpaceVariable, useDisclosure } from "caspeco-casper-ui";
import BookingConditions from "components/contact-information/booking-conditions";
import { PhoneField } from "components/contact-information/phone-field";
import { PrivacyPolicyModal } from "components/privacy-policy-modal";
import { hasFlag } from "helpers";
import { useBookingViewStore, useExternalBookingSettings } from "hooks";
import { webbookingSettingFlags } from "old/models/unitMetaData";
import WebBookingStepStatusEnum from "old/models/webBookingStepStatusEnum";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomerNameField from "./customer-name-field";
import EmailField from "./email-field";
import MessageField from "./message-field";
import NameField from "./name-field";
import NewsletterField from "./newsletter-field";
import PrivacyPolicyText from "./privacy-policy-text";
import SubmitButton from "./submit-button";

interface IContactInformationProps {
    finalizeBooking: () => void;
}

export interface IContactInformationForm {
    fname: string;
    lname: string;
    email: string;
    mobile: string;
    customerName: string;
    message: string;
    newsletter: boolean;
}

export const ContactInformation = ({ finalizeBooking }: IContactInformationProps) => {
    const { t } = useTranslation();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const externalBookingSettings = useExternalBookingSettings();
    const { activeStep, createParametersModel, selectedTimes, contactLoadedByFriendlyId } =
        useBookingViewStore();
    const { contact, message } = createParametersModel;

    const { control, handleSubmit, formState, trigger } = useForm<IContactInformationForm>({
        mode: "onBlur",
        defaultValues: {
            ...contact.toObject(),
            message,
        },
    });

    const onSubmit: () => Promise<void> = async () => finalizeBooking();

    function getContactValue(key: string) {
        return contactLoadedByFriendlyId && contactLoadedByFriendlyId[key]
            ? contactLoadedByFriendlyId[key]
            : contact[key];
    }
    const fnameValue = getContactValue("fname");
    const lnameValue = getContactValue("lname");
    const emailValue = getContactValue("email");
    const mobileValue = getContactValue("mobile");

    const displayMessageBox = externalBookingSettings && externalBookingSettings.displayMessageBox;
    const unitMetaData = externalBookingSettings.unitsMetaData;
    const showCustomerNameField = hasFlag(
        unitMetaData.binaryFlags,
        webbookingSettingFlags.displayCustomerName
    );
    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <BookingConditions />
            <Heading as="h5" fontWeight="500">
                {t("contactDetails")}
            </Heading>
            <Flex direction="column" gap={ThemeSpaceVariable.Medium} mt={ThemeSpaceVariable.Medium}>
                <NameField
                    value={fnameValue}
                    control={control}
                    variant="fname"
                    disabled={!!contactLoadedByFriendlyId?.fname}
                    trigger={trigger}
                />
                <NameField
                    value={lnameValue}
                    control={control}
                    variant="lname"
                    disabled={!!contactLoadedByFriendlyId?.lname}
                    trigger={trigger}
                />
                <PhoneField
                    value={mobileValue || ""}
                    control={control}
                    disabled={!!contactLoadedByFriendlyId?.mobile}
                    trigger={trigger}
                />
                <EmailField
                    value={emailValue || ""}
                    control={control}
                    disabled={!!contactLoadedByFriendlyId?.email}
                    trigger={trigger}
                />

                {showCustomerNameField && <CustomerNameField control={control} />}

                {displayMessageBox && <MessageField control={control} value={message} />}

                {externalBookingSettings.newsletter && <NewsletterField />}

                <PrivacyPolicyText onOpen={onOpen} />
                <PrivacyPolicyModal isOpen={isOpen} onClose={onClose} />
            </Flex>
            <SubmitButton
                isLoading={activeStep.status === WebBookingStepStatusEnum.ActivePending}
                isDisabled={!formState.isValid}
                isWaitList={selectedTimes.some((time) => time.isWaitList)}
            />
        </form>
    );
};
