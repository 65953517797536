import { ScaleFade } from "@chakra-ui/react";
import { Button, ThemeSpaceVariable, Tooltip } from "caspeco-casper-ui";
import Immutable, { List, Map } from "immutable";
import WebTimeRuleBookingTypeEnum from "old/models/bookingTypeOption";
import { Promises, getActivePromise } from "old/models/webBookingAPI";
import { WebBookingCreateParameters } from "old/models/webBookingCreateParameters";
import { WebBookingTimeRule } from "old/models/webBookingTimeRule";
import { WebBookingTimeRuleUnit } from "old/models/webBookingTimeRuleUnit";
import { WebBookingUnit } from "old/models/webBookingUnit";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getUrlParams, setSearch } from "utils";

interface IWebBookingTimeProps {
    time: WebBookingTimeRuleUnit;
    onTimeClick: (time: WebBookingTimeRuleUnit, timeSet: WebBookingTimeRule) => void;
    selectedTimes: Immutable.List<WebBookingTimeRuleUnit>;
    createParametersModel: WebBookingCreateParameters;
    timeSet: WebBookingTimeRule;
    activeStepLoading: boolean;
    advanced: boolean;
    isWaitListSlot?: boolean;
    webBookingUnits: List<WebBookingUnit>;
}

export default function WebBookingTime(props: IWebBookingTimeProps) {
    const isSelfLoading = useRef(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = getUrlParams();

    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        setIsOpen(true);
    }, []);

    const handleClick = async () => {
        if (isSelfLoading.current) return;

        isSelfLoading.current = true;

        props.onTimeClick(props.time, props.timeSet);

        const time = props.time.start.format("HHmm");
        const timeSetId = props.timeSet.id;

        setSearch(navigate, "r", "" + props.time.ruleId);
        setSearch(navigate, "time", `${time}`);

        const reservationPromise = getActivePromise(Promises.Reservation);
        const { guid } = await reservationPromise;
    };

    const allTimeSets =
        props.webBookingUnits &&
        Map<number, WebBookingTimeRule>(
            props.webBookingUnits.flatMap((unit: any) =>
                unit.sections.flatMap((section: any) =>
                    section.timeSets.map((timeSet: any) => [timeSet.id, timeSet])
                )
            )
        );

    const isOtherRuleTimeOverlapping = () => {
        const { time, timeSet } = props;
        return !!props.selectedTimes.find((x) => {
            if (x.ruleId === time.ruleId) return false; // Skip times in same rules
            const xTimeSet = allTimeSets?.get(x.ruleId);

            return (
                x.start.isBefore(time.end.clone().subtract(timeSet.recoupTime, "minutes")) &&
                time.start.isBefore(x.end.clone().subtract(xTimeSet.recoupTime, "minutes"))
            );
        });
    };

    const showEndTime = (time: WebBookingTimeRuleUnit) => {
        return allTimeSets?.get(time.ruleId).bookingType === WebTimeRuleBookingTypeEnum.Delivery;
    };

    const { time, selectedTimes, activeStepLoading, advanced, createParametersModel } = props;
    const selectedGroupSize = createParametersModel.guests;
    const isSelected = selectedTimes.indexOf(time) !== -1;

    const shouldDisableTime =
        advanced &&
        selectedTimes.count() === 1 &&
        ((selectedTimes.first()?.isWaitList &&
            time.sectionId !== selectedTimes.first()?.sectionId) ||
            (!selectedTimes.first()?.isWaitList && time.isWaitList));
    const disabled =
        activeStepLoading ||
        !(
            time.availableSeats.includes(selectedGroupSize) ||
            time.waitListSeats.includes(selectedGroupSize)
        ) ||
        (advanced && isOtherRuleTimeOverlapping()) ||
        shouldDisableTime;

    const relevantUnavailability =
        time.unavailability &&
        time.unavailability.find((x) => {
            return (
                (x.from == null || x.from <= selectedGroupSize) &&
                (x.to == null || x.to >= selectedGroupSize)
            );
        });
    const tooltip =
        relevantUnavailability != null
            ? t(`unavailabilityReasonEnum.${relevantUnavailability.reason}`)
            : t("noBookableResourcesForGroupSize");

    useEffect(() => {
        if (time.ruleId === params.ruleId && params.time === time.start.format("HHmm")) {
            handleClick();
        }
    }, []);
    return (
        <Tooltip key={time.start.format("HH:mm")} title={time.disabled ? tooltip : ""}>
            <ScaleFade initialScale={0.9} in={isOpen} transition={{ enter: { duration: 0.5 } }}>
                <Button
                    key={time.start.format("HH:mm")}
                    className="timeButton"
                    isDisabled={disabled}
                    onClick={handleClick}
                    isSelected={isSelected}
                    variant="card"
                    w="full"
                    px={ThemeSpaceVariable.Small}
                    size="lg"
                >
                    {time.start.format("HH:mm") +
                        (showEndTime(time) ? ` - ${time.end.format("HH:mm")}` : "")}{" "}
                    {props.isWaitListSlot && t("waitList.waitList")}
                </Button>
            </ScaleFade>
        </Tooltip>
    );
}
