import { Alert, Box, Flex, Icons } from "caspeco-casper-ui";
import { WebBookingUnit } from "old/models/webBookingUnit";
import { AvailableTimes } from "components/selection-components/select-time/available-times";
import { useExternalBookingSettings } from "api/apiHooks";
import { isErrorStep, getUrlParams } from "utils";
import { useBookingViewStore } from "hooks";
import { useTranslation } from "react-i18next";
import { SafeHTML } from "components/safe-HTML";
import { PhoneNumberLink } from "components/links/phone-number-link";
import TimeSetSkeleton from "./time-set-skeleton";
import { WebBookingSection } from "old/models/webBookingSection";
import { useBookingStore } from "hooks/bookingStore";
import { WebBookingTimeRule } from "old/models/webBookingTimeRule";
import SelectionHeader from "../selection-header";
import SomethingWrong from "components/error-components/something-wrong";

type SectionWithTextRule = {
    section: WebBookingSection;
    timeSet: WebBookingTimeRule;
};

export const SelectTime = () => {
    const { t } = useTranslation();
    const externalBookingSettings = useExternalBookingSettings();
    const {
        filteredUnitsResult,
        createParametersModel,
        selectedSections,
        webBookingUnitsResult,
        unitWithAvailableTimes,
        activeStep,
    } = useBookingViewStore();
    const { guests } = createParametersModel;
    const { onlineorder } = getUrlParams();
    const isLoading = webBookingUnitsResult
        ? ["not_loaded", "loading"].includes(webBookingUnitsResult.status)
        : true;
    const { webBookingUnits } = useBookingStore();

    const units = filteredUnitsResult.value;

    const allTimesUnavailable = units?.every((x: { sections: any[] }) =>
        x.sections.every((y: { timeSets: any[] }) =>
            y.timeSets.every((z: { times: any[] }) =>
                z.times.every(
                    (a: {
                        unavailability: any;
                        availableSeats: { contains: (arg0: number) => any };
                        disabled: any;
                    }) =>
                        a.unavailability === null &&
                        (!a.availableSeats.contains(guests === 0 ? 1 : guests) || a.disabled)
                )
            )
        )
    );

    const hasAnyWaitList = units?.some((x: { sections: any[] }) =>
        x.sections.some((y: { timeSets: any[] }) =>
            y.timeSets.some((z: { times: any[] }) =>
                z.times.some(
                    (a: { waitListSeats: any; disabled: any }) => a.waitListSeats && !a.disabled
                )
            )
        )
    );

    const { messageBookingClosed } = externalBookingSettings;

    const noAvailableTimes = allTimesUnavailable && selectedSections.count() > 0;

    const showDayClosed = noAvailableTimes && !messageBookingClosed && !onlineorder;

    const showMessageBookingClosed = noAvailableTimes && messageBookingClosed && !hasAnyWaitList;

    const hasTextRules = webBookingUnits.some((unit: WebBookingUnit) => {
        return unit.sections.some((section: WebBookingSection) => {
            return section.timeSets.some((timeSet: WebBookingTimeRule) => {
                return timeSet.isTextRow;
            });
        });
    });

    const showTextRules = () => {
        // Get sections with text rule
        const rules: SectionWithTextRule[] = [];
        webBookingUnits.forEach((unit: WebBookingUnit) => {
            unit.sections.forEach((section: WebBookingSection) => {
                section.timeSets.forEach((timeSet: WebBookingTimeRule) => {
                    if (timeSet.isTextRow) {
                        rules.push({ section, timeSet });
                    }
                });
            });
        });

        return rules.map((rule: SectionWithTextRule) => {
            return (
                <Alert
                    key={rule.timeSet.id}
                    title={rule.section.name}
                    type="info"
                    iconVerticalAlign={"flex-start"}
                >
                    <SafeHTML
                        className="textFromSettings"
                        element="div"
                        options={{
                            allowedTags: ["a", "br"],
                            allowedSchemes: ["tel", "mailto", "https"],
                        }}
                        html={rule.timeSet.title}
                    />
                </Alert>
            );
        });
    };

    return (
        <Box>
            {isLoading ? (
                <>
                    <SelectionHeader
                        id="timeChoiceHeader"
                        key="timeChoiseHeader"
                        icon={Icons.Time}
                        text={t("start.chooseTime")}
                    />
                    <TimeSetSkeleton />
                </>
            ) : isErrorStep(activeStep.step) ? (
                <SomethingWrong />
            ) : showMessageBookingClosed ? (
                <>
                    <Alert key="messageBookingClosed" type="info" iconVerticalAlign={"flex-start"}>
                        <SafeHTML
                            className="textFromSettings"
                            element="div"
                            options={{
                                allowedTags: ["a", "br"],
                                allowedSchemes: ["tel", "mailto", "https"],
                            }}
                            html={messageBookingClosed}
                        />
                    </Alert>
                    {hasTextRules && showTextRules()}
                </>
            ) : showDayClosed ? (
                <Flex key="dayClosedBox">
                    <Alert type="info" title={t("start.dayClosed")}>
                        <PhoneNumberLink />
                    </Alert>
                </Flex>
            ) : (
                <>
                    <SelectionHeader
                        id="timeChoiceHeader"
                        key="timeChoiseHeader"
                        icon={Icons.Time}
                        text={t("start.chooseTime")}
                    />

                    <AvailableTimes
                        webBookingUnit={unitWithAvailableTimes}
                        externalBookingSettings={externalBookingSettings}
                    />
                </>
            )}
        </Box>
    );
};
