import { AspectRatio, useBreakpointValue } from "@chakra-ui/react";
import {
    Box,
    Image as CaspecoImage,
    Skeleton,
    ThemeColorVariable,
    ThemeSpaceVariable,
} from "caspeco-casper-ui";
import { useExternalBookingSettingsQuery } from "hooks";
import Header from "layout/header";
import webBookingViewStore from "old/stores/webBookingViewStore";
import { useEffect, useRef, useState } from "react";
import { CSSVarHeaderHeight } from "style-variables";
import { getUrlParams } from "utils";
import "./layout.css";

const isIframe = window.self !== window.top;
let observer: ResizeObserver;

const Layout = ({ children }: { children: JSX.Element }) => {
    const [shouldHideCoverImage, setShouldHideCoverImage] = useState(
        webBookingViewStore.getHideCoverImage()
    );
    const { data: settings } = useExternalBookingSettingsQuery();
    const { isLandingPage } = getUrlParams();
    const [isCoverImageLoaded, setIsCoverImageLoaded] = useState(false);

    const aspectRatio = useBreakpointValue({
        base: 1 / 1,
        md: 32 / 9,
    });

    useEffect(() => {
        const storeOnChangeInternal = () => {
            const updatedState = webBookingViewStore.getHideCoverImage();
            setShouldHideCoverImage(updatedState);
        };
        webBookingViewStore.onChange(storeOnChangeInternal);
        storeOnChangeInternal();
        return () => {
            webBookingViewStore.offChange(storeOnChangeInternal);
        };
    }, []);

    const themeSettings = settings?.themeSettings;
    const coverImageUrl =
        aspectRatio === 1
            ? themeSettings?.coverImageOneByOne.imageUrl
            : themeSettings?.coverImageThirtyTwoByNine.imageUrl;
    const hasCoverImage = Boolean(
        themeSettings?.coverImageOneByOne.imageUrl &&
            themeSettings?.coverImageThirtyTwoByNine.imageUrl
    );

    const container = useRef(null);
    if (isIframe && container.current && !observer) {
        observer = new ResizeObserver((entries) => {
            const { height } = entries[0].contentRect;
            window.parent.postMessage(`caspeco-booking-height ${height}`, "*");
        });
        observer.observe(container.current);
    }

    useEffect(() => {
        const handleCoverImageLoad = () => {
            setIsCoverImageLoaded(true);
        };
        const coverImage = new Image();
        coverImage.src = coverImageUrl;
        coverImage.onload = handleCoverImageLoad;

        return () => {
            coverImage.onload = null;
        };
    }, [coverImageUrl]);

    return (
        <Box
            ref={container}
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            color={ThemeColorVariable.OnSurface}
        >
            <Header
                shouldHideCoverImage={shouldHideCoverImage}
                isLandingPage={isLandingPage}
                hasCoverImage={hasCoverImage}
            />

            <Box minH={CSSVarHeaderHeight} w="full">
                {(isLandingPage || (!shouldHideCoverImage && hasCoverImage)) && (
                    <AspectRatio
                        position="relative"
                        ratio={aspectRatio}
                        width="full"
                        height="full"
                        overflow="hidden"
                    >
                        {isCoverImageLoaded ? (
                            <CaspecoImage
                                src={coverImageUrl}
                                alt="Cover image"
                                objectFit="cover"
                                width="full"
                                height="full"
                            />
                        ) : (
                            <Skeleton
                                startColor={ThemeColorVariable.NeutralContainer}
                                endColor={ThemeColorVariable.NeutralContainer}
                                width="full"
                                height="full"
                            />
                        )}
                    </AspectRatio>
                )}
            </Box>
            <Box id="content" p={ThemeSpaceVariable.Large} w="100%" maxWidth="560px">
                {children}
            </Box>
        </Box>
    );
};

export default Layout;
