import SectionOfAvailableTimes from "components/selection-components/select-time/section-of-available-times";
import { ExternalBookingSettings } from "old/models/externalBookingSettings";
import { WebBookingUnit as WebBookingUnitModel } from "old/models/webBookingUnit";

interface IAvailableTimesProps {
    webBookingUnit: WebBookingUnitModel;
    externalBookingSettings: ExternalBookingSettings;
}

export function AvailableTimes(props: IAvailableTimesProps) {
    if (!props.webBookingUnit) {
        return null;
    }

    const filteredSections = props.webBookingUnit?.sections
        .filter((s) => {
            return s.timeSets.some(
                (ts) =>
                    ts.isTextRow || ts.times.some((t) => t.unavailability !== null || !t.disabled)
            );
        })
        .sortBy((x) => x.sortOrder)
        .toArray();

    const sections = filteredSections.map((section) => (
        <SectionOfAvailableTimes
            key={`section${section.id}`}
            webBookingSection={section}
            defaultExpanded={
                filteredSections.length === 1 ||
                props.externalBookingSettings?.defaultExpandedSectionIds?.includes(section.id)
            }
        />
    ));

    return <div className="available-times">{sections}</div>;
}
