import { Divider, Flex, Icons, ThemeSpaceVariable } from "caspeco-casper-ui";
import { useBookingViewStore, useExternalBookingSettings } from "hooks";
import { ChangeDate, ChangeModelField, PrevStep } from "old/actions/webBookingViewActions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDateDisplay, useTimeDisplay, setSearch } from "utils";
import EditSelectionButton from "./edit-selection-button";
import webBookingViewStore from "old/stores/webBookingViewStore";

export const EditSelectionButtonGroup = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { maxGuests } = useExternalBookingSettings().unitsMetaData;

    const { createParametersModel, date, selectedTimes } = useBookingViewStore();
    const { guests, guestsChildren } = createParametersModel;
    const { dateText } = useDateDisplay(date);

    const tooManyGuests = maxGuests && guests && guests > maxGuests;

    let guestText = t("start.numberGuests", { guests });
    if (guestsChildren > 0) {
        guestText += ` (${t("start.ofWhich", { children: guestsChildren })})`;
    }

    let time: string | null = null;
    if (selectedTimes.size) {
        time = webBookingViewStore.getSummary().time;
    }
    const { timeText } = useTimeDisplay(time, selectedTimes);

    //TODO: we should make the date in useBookingViewStore use date-fns instead of moment!

    const resetGuests = () => {
        resetDate();
        setSearch(navigate, "guests");
        setSearch(navigate, "children");
        new ChangeModelField("guests", null);
        if (guestsChildren !== null) new ChangeModelField("guestsChildren", null);
    };
    const resetDate = () => {
        resetTime();
        setSearch(navigate, "date");
        if (date) new ChangeDate(null);
    };
    const resetTime = () => {
        setSearch(navigate, "r");
        setSearch(navigate, "time");
        if (time) {
            new ChangeDate(date);
            new PrevStep();
        }
    };

    return (
        <Flex flexDirection="column" gap={ThemeSpaceVariable.Small}>
            {guests && !tooManyGuests && (
                <EditSelectionButton
                    icon={Icons.UserGroup}
                    text={guestText}
                    onClick={resetGuests}
                />
            )}
            {date && <EditSelectionButton icon={Icons.Date} text={dateText} onClick={resetDate} />}
            {time && <EditSelectionButton icon={Icons.Time} text={timeText} onClick={resetTime} />}
            {guests && !tooManyGuests && (
                <Divider display="block" my={ThemeSpaceVariable.Large} gridColumn="span 4" />
            )}
        </Flex>
    );
};
