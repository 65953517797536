import { DatePicker } from "@caspeco/casper-ui-library.components.datepicker";
import { Icons } from "caspeco-casper-ui";
import Spinner from "components/spinner/spinner";
import { useBookingViewStore, useExternalBookingSettings } from "hooks";
import {
    useBookingStore,
    useClosedDays,
    useFirstAvailableDay,
    useFullyBookedDays,
    useHasAvailableDaysForMonth,
    useWaitlistDays,
} from "hooks/bookingStore";
import { List } from "immutable";
import moment from "moment";
import { loadAvailableTimes } from "old/actions/webBookingActionCreators";
import { ChangeDate } from "old/actions/webBookingViewActions";
import { ChangeDateEvent } from "old/models/bookingEvent";
import webBookingViewStore from "old/stores/webBookingViewStore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import i18n from "translations/config/i18n";
import {
    getUrlParams,
    handleEvent,
    isDateWithinBookingWindow,
    setSearch,
    shouldDisableDate,
} from "utils";
import SelectDateSkeleton from "../select-date-skeleton";
import SelectionHeader from "../selection-header";
import FirstAvailableDay from "./first-available-day";
import DatePickerLegend from "./date-picker-legend";
import { add, startOfMonth } from "date-fns";

interface ISelectDateProps {
    selectedMonth: Date;
    setSelectedMonth: (date: Date) => void;
}

export const SelectDate = ({ selectedMonth, setSelectedMonth }: ISelectDateProps) => {
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [datePickerLocale, setDatePickerLocale] = useState(i18n.language);

    const { viewedMonthDayStates, date, createParametersModel } = useBookingViewStore();
    const { loadedInitalDayState } = useBookingStore();
    const firstAvailableDay = useFirstAvailableDay();
    const hasAvailableDaysForMonth = useHasAvailableDaysForMonth(selectedMonth);

    const externalBookingSettings = useExternalBookingSettings();

    const params = getUrlParams();
    const { unitId, sectionIds, system, debug } = params;

    const closedDays = useClosedDays(selectedMonth);
    const waitlistDays = useWaitlistDays(selectedMonth, createParametersModel.guests);
    const fullyBookedDays = useFullyBookedDays(selectedMonth, createParametersModel.guests);

    const goToNextAvailableDay = () => {
        const date = firstAvailableDay?.day.toDate();
        handleMonthChange(date);
    };

    const handleDateChange = (date: Date) => {
        setLoading(true);
        const m = moment(date).local().startOf("day");
        handleEvent(new ChangeDateEvent(m));
        loadAvailableTimes(List([m]), List([unitId]), List(sectionIds), system, debug);
        new ChangeDate(m);
        const urlDate = m.format("YYYY-MM-DD");
        if (params.date === null || params.date.format("YYYY-MM-DD") !== urlDate)
            setSearch(navigate, "date", urlDate);
    };

    const getMonthKey = (date: IMoment): string => `ds:${date.year()}_${date.month() + 1}`;

    const handleMonthChange = (date: Date) => {
        setSelectedMonth(date);

        const newMonthStart = moment(date).startOf("month");
        // check if This (viewed) month needs to be loaded
        const newMonthKey = getMonthKey(newMonthStart);
        if (viewedMonthDayStates.has(newMonthKey) === false) {
            webBookingViewStore.getLoadedMonthDayStateResult(
                newMonthStart,
                unitId,
                List(sectionIds),
                system
            );
        }

        // check if we should pre-load next month (the month after the currently viewed one)
        const nextMonthStart = newMonthStart.clone().add(1, "month");
        const nextMonthKey = getMonthKey(nextMonthStart);
        if (viewedMonthDayStates.has(nextMonthKey) === false) {
            webBookingViewStore.getLoadedMonthDayStateResult(
                nextMonthStart,
                unitId,
                List(sectionIds),
                system
            );
        }
    };

    const supportedDatepickerLangs = ["en-US", "nb-NO", "da-DK", "sv-SE", "en-GB"];
    const setLocaleKey = (lng: string) => {
        let selectedLocaleKey = supportedDatepickerLangs.includes(lng) ? lng : "en-US";
        // Business requirement: We need the date picker to start the week on Monday not sunday as for en-US
        if (selectedLocaleKey === "en-US") selectedLocaleKey = "en-GB";
        setDatePickerLocale(selectedLocaleKey);
    };

    useEffect(() => {
        const handleLanguageChange = (lng: string) => setLocaleKey(lng);

        i18n.on("languageChanged", handleLanguageChange);
        setLocaleKey(i18n.language);

        return () => {
            i18n.off("languageChanged", handleLanguageChange);
        };
    }, []);

    useEffect(() => {
        if (params.date !== null) {
            handleDateChange(params.date.toDate());
        }
    }, []);

    if (loading) return <Spinner />;

    const nextMonthDate = add(selectedMonth, { months: 1 });
    const nextMonth = startOfMonth(nextMonthDate);
    const isLastAvailableMonth = !isDateWithinBookingWindow(nextMonth, externalBookingSettings);
    return (
        <>
            <SelectionHeader id="chooseDateHeader" icon={Icons.Date} text={t("start.chooseDate")} />
            {loadedInitalDayState ? (
                <>
                    <DatePicker
                        key={selectedMonth.toString()}
                        selectedDate={date?.toDate()}
                        onDateChange={handleDateChange}
                        localeString={datePickerLocale}
                        selectedMonth={selectedMonth}
                        onMonthChange={(date: Date) => handleMonthChange(date)}
                        strikeThroughDates={closedDays}
                        dots={{
                            yellowDotDates: waitlistDays,
                            redDotDates: fullyBookedDays,
                        }}
                        shouldDisableDate={(date: Date) =>
                            shouldDisableDate(date, externalBookingSettings)
                        }
                        disablePastMonths
                        disableForwardNavigation={isLastAvailableMonth}
                    />
                    <DatePickerLegend
                        fullyBookedDays={fullyBookedDays}
                        waitlistDays={waitlistDays}
                    />
                </>
            ) : (
                <SelectDateSkeleton />
            )}

            {firstAvailableDay && !hasAvailableDaysForMonth && (
                <FirstAvailableDay
                    dayState={firstAvailableDay}
                    goToNextAvailableDay={goToNextAvailableDay}
                />
            )}
        </>
    );
};
