import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    ThemeColorVariable,
} from "caspeco-casper-ui";
import { validateName } from "helpers";
import { ChangeContactField } from "old/actions/webBookingViewActions";
import { ChangeEvent } from "react";
import { Control, UseFormTrigger, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IContactInformationForm } from "./contact-information";

interface INameFieldProps {
    value: string;
    control: Control<IContactInformationForm, any>;
    disabled?: boolean;
    variant: "fname" | "lname";
    trigger: UseFormTrigger<IContactInformationForm>;
}

const NameField = ({ variant, control, disabled, value, trigger }: INameFieldProps) => {
    const { t } = useTranslation();
    const {
        field,
        fieldState: { error },
    } = useController({
        control,
        name: variant,
        rules: {
            required: t(`contact.validation.${variant}`),
            validate: {
                validName: (n) => validateName(n, variant),
            },
        },
    });

    const handleContactChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        field.onChange(e.target.value);
        if (error) {
            trigger(field.name);
        }
        new ChangeContactField(variant, value);
    };

    const autoComplete = variant === "fname" ? "given-name" : "family-name";
    return (
        <FormControl isInvalid={!!error}>
            <FormLabel>
                {t(`contact.${variant}`)}{" "}
                <Box as="span" color={ThemeColorVariable.Error}>
                    *
                </Box>
            </FormLabel>
            <Input
                size="lg"
                id={variant}
                name={variant}
                autoComplete={autoComplete}
                onChange={handleContactChange}
                value={value}
                isDisabled={disabled}
                onBlur={field.onBlur}
                ref={field.ref}
            />
            <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
    );
};

export default NameField;
